import { Component } from '@angular/core';
import { Routes } from '@angular/router';

import { BadParameterComponent } from '../error-pages/bad-parameter';
import { PageNotFoundComponent } from '../error-pages/page-not-found';
import { UserNavigationGuard } from '../config/user-navigation.guard';
import { ComponentsPageGuard } from '../config/components-page.guard';
import { NoRightComponent } from '../error-pages/no-rights';
import { HomeRedirectService } from './home-redirect-service';

@Component({
  template: '<div></div>',
})
class TestErrorComponent {
  constructor() {
    throw new Error('Test error');
  }
}

const lazyLoadMapDashboardComponent = () =>
  import('../dashboards/map/map-dashboard.component').then(x => x.MapDashboardComponent);

const lazyLoadScheduleDashboardComponent = () =>
  import('../dashboards/schedule/schedule').then(x => x.ScheduleDashboardComponent);

const lazyLoadComparatorDashboardComponent = () =>
  import('../dashboards/comparator/comparator-dashboard.component').then(x => x.ComparatorDashboardComponent);

export const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    resolve: [HomeRedirectService],
    loadComponent: lazyLoadMapDashboardComponent,
  },
  {
    path: 'error-front',
    component: TestErrorComponent,
  },
  {
    path: 'dashboard',
    children: [
      {
        path: 'map',
        loadComponent: lazyLoadMapDashboardComponent,
      },
      {
        path: 'light-map',
        loadComponent: lazyLoadMapDashboardComponent,
      },
      {
        path: 'schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'port-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'global-smi-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'construction-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'construction-field-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'construction-windfarm-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'construction-dredging-project-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'charter',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'windfarm-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'field-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'dredging-project-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'activity',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'vessel-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'poi-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'global-smi-activity',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'prospects-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'custom-prospect-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'cvs',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'tracking',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'global-smi-tracking',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'marine-spread-schedule',
        loadComponent: lazyLoadScheduleDashboardComponent,
      },
      {
        path: 'table/:entityName',
        loadComponent: (): Promise<any> =>
          import('../database/entity-table-dashboard').then(x => x.EntityTableDashboard),
      },
      {
        path: 'database-overview',
        loadComponent: (): Promise<any> =>
          import('../database/database-overview').then(x => x.DatabaseOverviewComponent),
      },
      {
        path: 'global-alert-subscription',
        loadComponent: (): Promise<any> =>
          import('../subscription/alert-subscription-list').then(x => x.AlertSubscriptionListComponent),
      },
      {
        path: 'resource-gallery',
        loadComponent: (): Promise<any> =>
          import('../resource-gallery/resource-gallery-dashboard.component').then(x =>
            x.ResourceGalleryDashboardComponent
          ),
      },
      {
        path: 'page/:pageName',
        loadComponent: (): Promise<any> => import('../pages/components-page').then(x => x.ComponentsPage),
        canActivate: [ComponentsPageGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'bad-parameter',
        component: BadParameterComponent,
      },
      {
        path: 'user-admin',
        loadComponent: () => import('../dashboards/admin/user-admin').then(x => x.UserAdminComponent),
      },
      {
        path: 'right-summary-admin',
        loadComponent: () => import('../dashboards/admin/right-summary').then(x => x.RightSummaryWrapperComponent),
      },
      {
        path: 'vessel-comparator',
        loadComponent: lazyLoadComparatorDashboardComponent,
      },
      {
        path: 'quay-comparator',
        loadComponent: lazyLoadComparatorDashboardComponent,
      },
      {
        path: 'analysts',
        loadChildren: (): Promise<any> => import('../analyst/analyst.module').then(m => m.AnalystModule),
      },
      { path: 'dpr-vessels-dashboard', redirectTo: 'dpr/vessels', pathMatch: 'full' },
      { path: 'dpr-report-dashboard', redirectTo: 'dpr/selected-vessel', pathMatch: 'full' },
      { path: 'dpr-report', redirectTo: 'dpr/selected-report', pathMatch: 'full' },
      {
        path: 'dpr',
        loadChildren: (): Promise<any> => import('../live-dpr/live-dpr.module').then(m => m.LiveDprModule),
      },
      {
        path: 'wind-farm-simulation',
        loadComponent: (): Promise<any> =>
          import('../wind-farm-simulation/wind-farm-simulation').then(x => x.WindFarmSimulationComponent),
      },
      {
        path: 'voyage-optimizer',
        loadComponent: (): Promise<any> =>
          import('../voyage-optimizer/voyage-optimizer.component').then(x => x.VoyageOptimizerComponent),
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent,
      },
    ],
    canActivate: [UserNavigationGuard],
  },
  {
    path: 'no-rights',
    component: NoRightComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
