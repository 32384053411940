import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';

dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(duration);
dayjs.extend(isoWeek);

export let APP_LOCALE: string = (window.navigator as any).userLanguage || window.navigator.language;
/*
 * navigator language will be en-GB while dayjs format corresponding will be en-gb so we have to put the APP_LOCAL
 * language in lowercase
 */
APP_LOCALE = APP_LOCALE ? APP_LOCALE.toLowerCase() : null;

/*
 * Check if we have loaded a dayjs format local which include APP_LOCALE substring
 * If it's the case and current APP_LOCALE variable isn't already matching a dayjs locale format
 * we will select this dayjs locale format
 * For exemple if browser language is fr-fr we want to use 'fr' dayjs locale format
 */
Object.keys(dayjs.Ls).forEach(dayjsLocale => {
  if (APP_LOCALE && !dayjs.Ls[APP_LOCALE] && APP_LOCALE.includes(`${dayjsLocale}-`)) {
    APP_LOCALE = dayjsLocale;
  }
});
// Fallback if user doesn't have a local language or if we don't have the format for this language
if (!APP_LOCALE || !dayjs.Ls || !dayjs.Ls[APP_LOCALE]) {
  APP_LOCALE = 'en';
}
dayjs.locale(APP_LOCALE);
console.info('Day JS initialized with Locale: ' + APP_LOCALE);
