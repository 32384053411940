import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { timer } from 'rxjs';

import { DialogManager } from '../database/dialog-manager';
import { environment } from '../environments/environment';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';

interface SpinWorkerAppData {
  version: string;
}

interface SemVer {
  major: number;
  minor: number;
  patch: number;
  build: number;
  isEmpty: boolean;
  parsed: [number, number, number, number];
  text: string;
}

/**
 * Delay between each checkForUpdate call
 */
const CHECK_FOR_UPDATE_DELAY_MS = 60000;

@Injectable({
  providedIn: 'root',
})
/**
 * This service handles the communication with the service worker.
 * The purpose of this service is to check the app version loaded by the user
 * and compare it with the most recent version deployed.
 * If a major version change is detected, we force a reload for the user.
 */
export class ServiceWorkerStateService {
  public currentVersion: SemVer;
  private installationFailed: boolean = false;
  constructor(private dialogManager: DialogManager, updates: SwUpdate) {
    if (!environment.serviceWorker) {
      return;
    }

    /**
     * This condition is necessary after a hard fresh.
     * Indeed after a hard refresh, the serviceWorker controller is initialized to null.
     * (this is not a bug, but a desired behavior (see https://web.dev/service-worker-lifecycle/#shift-reload).
     *
     * First, we test if we have serviceWorker, which is not always the case.
     * (e.g. in the case of private browsing under Mozilla)
     */
    if ('serviceWorker' in navigator) {
      if (navigator.serviceWorker.controller) {
        this.subscribeApplicationUpdates(updates);
        return;
      }
      // A solution if the controller is not set is to unregister and register again manually.
      const url = window.location.protocol + '//' + window.location.host + '/ngsw-worker.js';
      navigator.serviceWorker.getRegistration(url).then(sw => {
        if (sw) {
          sw.unregister().then(() => {
            navigator.serviceWorker.register('/ngsw-worker.js').then(() => {
              this.subscribeApplicationUpdates(updates);
            });
          });
        }
      });
    }
  }

  /**
   * Handles application updates from the Angular Service Worker.
   *
   * @param {SwUpdate} updates - Angular Service Worker update service.
   *
   * @example
   * - In unrecoverable SW states, prompts the user to reload.
   * - On 'NO_NEW_VERSION_DETECTED', stores the current version.
   * - On 'VERSION_DETECTED', if a new major version is found, reloads the app.
   * - Regularly checks for updates based on `CHECK_FOR_UPDATE_DELAY_MS`.
   */
  public subscribeApplicationUpdates(updates: SwUpdate): void {
    /*
     * Handle SW unrecoverable state:
     * https://angular.io/guide/service-worker-communications#handling-an-unrecoverable-state
     * It will ask the user to reload the page to fix the SW state.
     */
    updates.unrecoverable.subscribe(_ => {
      this.dialogManager.showMessage(
        'An error occurred that we cannot recover from.\nPlease reload the page.',
        'error',
        { duration: 100_000 },
      );
    });

    updates.versionUpdates.subscribe(data => {
      if (data.type === 'VERSION_INSTALLATION_FAILED' && !this.installationFailed) {
        // we log the error to investigate it and store a state so we don't log the error every X seconds
        this.installationFailed = true;
        const spinWorkerAppData = data.version.appData as SpinWorkerAppData;
        console.warn(`Service worker installation failed for version ${spinWorkerAppData.version} - ${data.error}`);
        return;
      }

      this.installationFailed = false;
      if (data.type === 'NO_NEW_VERSION_DETECTED') {
        const spinWorkerAppData = data.version.appData as SpinWorkerAppData;
        const parsed = ServiceWorkerStateService.parseSemVer(spinWorkerAppData.version);
        this.currentVersion = parsed;
      } else if (data.type === 'VERSION_DETECTED') {
        const spinWorkerAppData = data.version.appData as SpinWorkerAppData;
        const parsed = ServiceWorkerStateService.parseSemVer(spinWorkerAppData.version);
        if (this.currentVersion && parsed.major > this.currentVersion.major) {
          alert(`
          A new version of the application is available!
          This page will be refreshed to get the latest software updates.`);
          console.info(`New version detected (${parsed.text}) - Reloading the app...`);
          window.location.reload();
        } else {
          // In some case VERSION_DETECTED can be the first event we received
          this.currentVersion = parsed;
        }
      }
    });

    timer(0, CHECK_FOR_UPDATE_DELAY_MS).subscribe(() => {
      updates.checkForUpdate();
    });
  }

  private static parseSemVer(version: string): SemVer {
    const m = version.match(/\d*\.|\d+/g) || [];

    const major = +m[0] || 0;
    const minor = +m[1] || 0;
    const patch = +m[2] || 0;
    const build = +m[3] || 0;
    return {
      major,
      minor,
      patch,
      build,
      isEmpty: !major && !minor && !patch && !build,
      parsed: [major, minor, patch, build],
      text: version,
    };
  }
}
