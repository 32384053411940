import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Config } from 'src/config/config';

import { AppContextResponse } from '../helpers/app-types';

/**
 * This service is used to redirect to the user default dashboard.
 * The default dashboard is located in `config.defaultDashboard`. We need to wait for the config to be loaded
 * before redirecting.
 */
@Injectable({ providedIn: 'root' })
export class HomeRedirectService {
  private config: Config;
  constructor(private router: Router, injector: Injector) {
    this.config = injector.get(Config);
  }

  async resolve(): Promise<void> {
    // Redirect to default dashboard once the config is loaded
    return this.config.loadingPromise.then((config: AppContextResponse) => {
      const defaultDashboard = Config.getDefaultDashboard(config.appConfig, config.appParams.product);
      this.router.navigate([`dashboard/${defaultDashboard}`]);
    });
  }
}
