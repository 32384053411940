<div>
  <h3 *ngIf="displayTitle" class="fleet-list-title">{{ listTitle }}</h3>
  <div
    *ngFor="let vesselFleet of vesselFleets | searchSidebarItem: searchText"
    class="item"
    (click)="selectVesselFleet($event, vesselFleet)"
    [ngClass]="{ selected: vesselFleet.selected }"
    data-testid="fleet-sidebar-item">
    <div class="item-information">
      <div>
        <div class="title" *ngIf="!vesselFleet.editing">{{ vesselFleet.title }}</div>
        <div class="title-editing" *ngIf="vesselFleet.editing">
          <pearl-form-field
            label="Enter your title"
            [showError]="vesselFleet.newTitle.invalid"
            [errorMessage]="getErrorMessage(vesselFleet.newTitle)">
            <input
              matInput
              #titleInput
              (keydown.enter)="onTitleEnter($event, vesselFleet)"
              [formControl]="vesselFleet.newTitle"
              required
              cdkFocusInitial
              autocomplete="off" />
          </pearl-form-field>
        </div>
        <mat-checkbox
          *ngIf="config.canShareFleet() && vesselFleet.editing"
          class="share-fleet-checkbox mat-primary"
          [matTooltip]="'Share your fleet within ' + config.userInfo.client"
          matTooltipPosition="after"
          [color]="'primary'"
          [(ngModel)]="vesselFleet.newSharedFleet">
          Shared fleet
        </mat-checkbox>
      </div>
      <div class="title">{{ vesselFleet.dashboard | upperFirstLetter }}</div>
      <div class="date">
        {{ getFormattedDate(vesselFleet.dateVesselFleet) }}
        <ng-container *ngIf="config.canShareFleet() && vesselFleet.editing">
          <span> - </span>
          <span> Fleet ID: {{ vesselFleet.id }} </span>
        </ng-container>
      </div>
      <div class="subtitle">{{ vesselFleetCountInfo(vesselFleet) }}</div>
    </div>
    <div *ngIf="!vesselFleet.editing && !vesselFleet.deleting">
      @if (vesselFleet.bookmarks && vesselFleet.bookmarks.length) {
        <pearl-icon
          icon="bookmark"
          [size]="24"
          matBadge="{{ vesselFleet.bookmarks.length }}"
          matBadgeSize="small"
          matBadgeColor="accent">
        </pearl-icon>
      }
    </div>
    <div class="action-buttons">
      <button
        *ngIf="canEdit && !vesselFleet.editing && !vesselFleet.deleting"
        mat-button
        class="more-button"
        [matMenuTriggerFor]="addMenu"
        matTooltip="Actions"
        matTooltipPosition="above"
        (click)="preventDefault($event)">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #addMenu="matMenu" class="edit-menu">
        <button
          class="edit-button"
          (click)="enterEditMode($event, vesselFleet)"
          mat-icon-button
          color="primary"
          *ngIf="!vesselFleet.editing && canEdit"
          [matTooltip]="'Edit'">
          <mat-icon>create</mat-icon>
        </button>
        <button
          class="delete-button"
          (click)="enterDeletingMode($event, vesselFleet)"
          mat-icon-button
          color="warn"
          *ngIf="!vesselFleet.editing"
          matTooltip="Delete">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-menu>
      <button
        class="edit-filters-button"
        (click)="loadExistingVesselFleet(vesselFleet)"
        mat-icon-button
        color="primary"
        *ngIf="vesselFleet.editing"
        matTooltip="Edit filters">
        <mat-icon>filter_list</mat-icon>
      </button>
      <button
        class="save-button"
        (click)="saveClick($event, vesselFleet)"
        mat-icon-button
        color="primary"
        *ngIf="vesselFleet.editing"
        [disabled]="!vesselFleet.newTitle.value"
        matTooltip="Save">
        <mat-icon>save</mat-icon>
      </button>
      <button
        class="delete-button"
        (click)="checkIfCanDeleteVesselFleet($event, vesselFleet)"
        mat-icon-button
        color="warn"
        *ngIf="vesselFleet.deleting"
        matTooltip="Delete">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        class="cancel-button"
        (click)="cancelAction($event, vesselFleet)"
        mat-icon-button
        color="primary"
        *ngIf="vesselFleet.editing || vesselFleet.deleting"
        matTooltip="Cancel">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</div>
