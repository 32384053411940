import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Output,
  inject } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { isEqual } from 'lodash-es';

import { AppInfoService } from '../app/app-info-service';
import { Config } from '../config/config';
import { BaseSidebarItem, SidebarEditedItem } from '../user-saving/user-saving-types';
import { SearchSidebarItemPipe } from '../helpers/pipes';
import { UIService } from '../shared/services/ui.service';
import { PearlFormFieldComponent } from '../shared/pearl-components';

@Component({
  selector: 'project-sidebar',
  templateUrl: './project-sidebar.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PearlFormFieldComponent,
    MatInputModule,
    FormsModule,
    NgFor,
    NgClass,
    MatCheckboxModule,
    MatButtonModule,
    SearchSidebarItemPipe,
    MatIconModule,
  ],
})
export class OsvProjectSidebarComponent {
  constructor(injector: Injector) {
    this.cdRef = injector.get(ChangeDetectorRef);
    this.config = injector.get(Config);
    this.appInfoService = injector.get(AppInfoService);
    this.selectedOsvProjectSnapshot = [...this.config.selectedOsvProjectIds].sort((a, b) => a - b);
  }

  public cdRef: ChangeDetectorRef;
  public config: Config;
  public appInfoService: AppInfoService;

  private readonly selectedOsvProjectSnapshot: number[];

  public searchText: string = '';
  public hoverMessage: string;

  public uiService = inject(UIService);

  // "true" emitted if a change is applicable
  @Output()
  osvProjectSelected = new EventEmitter<boolean>();
  @Output()
  osvProjectApplied = new EventEmitter();
  // Event trigger when the project sidebar is closed by the small-display-close-button
  @Output()
  smallDisplayCloseProjectSidebar = new EventEmitter<void>();

  public get isApplicable(): boolean {
    return !isEqual(this.selectedOsvProjectSnapshot, this.config.selectedOsvProjectIds.sort((a, b) => a - b));
  }

  public isSelected(project: BaseSidebarItem): boolean {
    return this.config.selectedOsvProjectIds.includes(project.id);
  }

  public setHoverMessage(event: MouseEvent | null, project: BaseSidebarItem): void {
    /*
     * Determine if we hover the checkbox. We cannot really bind a mouseenter/leave on checkbox since it's hollow
     * and will dispatch a leave when the mouse is inside an empty checkbox
     */
    const isCheckbox = event ? (event.target as HTMLElement).closest('.item-selection') !== null : false;
    if (isCheckbox) {
      this.hoverMessage = 'Toggle';
    } else if (this.isAloneSelected(project)) {
      this.hoverMessage = 'All';
    } else {
      this.hoverMessage = 'Only';
    }
    this.cdRef.detectChanges();
  }

  public applyProjectSelection(): void {
    this.osvProjectApplied.emit();
  }

  public isAloneSelected(project: BaseSidebarItem): boolean {
    return this.config.selectedOsvProjectIds.length === 1 && this.config.selectedOsvProjectIds.includes(project.id);
  }

  // A project checkbox is selected: add/remove it from the selection
  public toggleProject(project: SidebarEditedItem, event: MatCheckboxChange): void {
    if (this.isAloneSelected(project)) { // Select all
      this.config.selectedOsvProjectIds = this.config.userInfo?.accessibleOsvProjects.map(p => p.id);
      // Set the checkbox manually back to true, as it's not done automatically by the checkbox component
      event.source.checked = true;
    } else if (this.isSelected(project)) { // Remove from selection
      this.config.selectedOsvProjectIds = this.config.selectedOsvProjectIds.filter(pid => pid !== project.id);
    } else this.config.selectedOsvProjectIds.push(project.id);
    this.osvProjectSelected.emit(this.isApplicable);
  }

  /*
   * A project is clicked (not the checkbox):
   * - If there are other selected projects, select the clicked one alone
   * - If it is the only selected, select all projects
   */
  public projectTitleSelected(project: SidebarEditedItem): void {
    if (this.isAloneSelected(project)) { // Select all
      this.config.selectedOsvProjectIds = this.config.userInfo?.accessibleOsvProjects.map(p => p.id);
    } else { // Retain only clicked project
      this.config.selectedOsvProjectIds = [project.id];
    }
    this.setHoverMessage(null, project);
    this.osvProjectSelected.emit(this.isApplicable);
  }

  public closeProjectSidebar(): void {
    this.smallDisplayCloseProjectSidebar.emit();
  }
}
