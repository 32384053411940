import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Output,
  ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { orderBy, remove } from 'lodash-es';

import { AppInfoService } from '../app/app-info-service';
import { Config } from '../config/config';
import { DialogManager } from '../database/dialog-manager';
import { FilterHelper } from '../filters/filter-helper';
import { SelectorState } from '../selector/selector.types';
import { VesselData } from '../helpers/types';
import { VesselFleet } from './user-saving-types';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { VesselFleetListComponent } from './vessel-fleet-list';
import { RefDataProvider } from '../data-loader/ref-data-provider';
import { UIService } from '../shared/services/ui.service';
import { SpinTooltipDirective } from '../shared';
import { PearlFormFieldComponent } from '../shared/pearl-components';

@Component({
  selector: 'vessel-fleet-sidebar',
  templateUrl: './vessel-fleet-sidebar.html',
  styleUrls: ['vessel-fleet-sidebar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PearlFormFieldComponent,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    NgClass,
    VesselFleetListComponent,
    SpinTooltipDirective,
  ],
})
export class VesselFleetSidebarComponent implements AfterViewInit {
  constructor(injector: Injector, public fleetSidebarDialog: MatDialog) {
    this.cdRef = injector.get(ChangeDetectorRef);
    this.dialogManager = injector.get(DialogManager);
    this.config = injector.get(Config);
    this.appInfoService = injector.get(AppInfoService);
    this.analyticsService = injector.get(ProductAnalyticsService);
  }
  public sharedVesselFleets: VesselFleet[] = [];
  public vesselFleets: VesselFleet[] = [];
  public cdRef: ChangeDetectorRef;
  private dialogManager: DialogManager;
  public config: Config;
  public appInfoService: AppInfoService;
  private analyticsService: ProductAnalyticsService;

  public uiService = inject(UIService);

  public searchText: string = '';
  public vesselDataset: VesselData[] = [];
  @Output()
  vesselFleetSelected = new EventEmitter<VesselFleet[]>();
  // Event trigger when the vessel fleet sidebar is closed by the small-display-close-button
  @Output()
  smallDisplayCloseVesselFleetSidebar = new EventEmitter<void>();
  @ViewChild('titleInput')
  titleInput: Element;

  get allVesselFleets(): VesselFleet[] {
    return [...this.sharedVesselFleets, ...this.vesselFleets];
  }

  public get createFleetDisabled(): boolean {
    return this.config.selectedFleets?.length > 0;
  }

  public ngAfterViewInit(): void {
    this.vesselDataset = RefDataProvider.currentProjectVesselDataset;
    this.prepareVesselFleets();
  }

  public prepareVesselFleets(): void {
    let vesselFleets = this.config.getAvailableVesselFleets();
    /*
     * go over all fleet that we have received and determine the total count of vessels
     * and the count of active vessels
     */
    for (const vesselFleet of vesselFleets) {
      VesselFleetSidebarComponent.updateVesselCounts(vesselFleet, this.vesselDataset);
      vesselFleet.newTitle = new FormControl('', [Validators.required]);
    }

    // Last vessel fleets are on top
    vesselFleets = orderBy(vesselFleets, s => [s.sharedFleet, s.dateVesselFleet], ['asc', 'desc']);

    // split fleets into shared and user-specific
    this.sharedVesselFleets = vesselFleets.filter(vs => vs.sharedFleet);
    this.vesselFleets = vesselFleets.filter(vs => !vs.sharedFleet);
    this.updateSelectedFleets();
  }

  public static updateVesselCounts(vesselFleet: VesselFleet, vesselList: VesselData[]): void {
    let totalFleetCount = 0;
    let activeFleetCount = 0;
    vesselList.forEach(vessel => {
      if (FilterHelper.filter(vesselFleet.filters, vessel)) {
        totalFleetCount++;
        if (vessel.isNotRetiredNorCancelled) {
          activeFleetCount++;
        }
      }
    });
    vesselFleet.vesselsCount = totalFleetCount;
    vesselFleet.activeVesselsCount = activeFleetCount;
  }

  public updateSelectedFleets(): void {
    this.allVesselFleets.forEach(f => f.selected = this.config.selectedFleets?.includes(f.id));
    this.cdRef.detectChanges();
  }

  public createVesselFleet(): void {
    const selectorState: SelectorState = {
      ...this.config.appConfig.fleetBuilder,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      afterFilterAction: () => this.afterCreateNewFleet(),
      appliedFilters: {},
      fleetBuilderMode: 'create',
    };
    this.dialogManager.openSelectorDialog(selectorState);
  }

  public async afterCreateNewFleet(): Promise<void> {
    await this.config.loadAvailableVesselFleets();
    this.prepareVesselFleets();
  }

  public onSelectVesselFleet(): void {
    const selectedVesselFleets = this.allVesselFleets
      .filter(vesselFleet => vesselFleet.selected);
    this.vesselFleetSelected.emit(selectedVesselFleets);
  }

  public leaveAllFleet(): void {
    this.allVesselFleets.forEach(vs => vs.selected = false);
    this.vesselFleetSelected.emit([]);
    this.analyticsService.trackAction('allCustomFleetsUnapplied');
  }

  public updateListAfterShared(vesselFleet: VesselFleet): void {
    if (vesselFleet.sharedFleet) {
      remove(this.vesselFleets, vS => vS.id === vesselFleet.id);
      this.sharedVesselFleets.push(vesselFleet);
    } else {
      remove(this.sharedVesselFleets, vS => vS.id === vesselFleet.id);
      this.vesselFleets.push(vesselFleet);
    }
    this.sharedVesselFleets = orderBy(this.sharedVesselFleets, s => s.dateVesselFleet, 'desc');
    this.vesselFleets = orderBy(this.vesselFleets, s => s.dateVesselFleet, 'desc');
  }

  public closeVesselFleetSidebar(): void {
    this.smallDisplayCloseVesselFleetSidebar.emit();
  }
}
