<ng-template #logout>
  <a
    id="buttonAsLink"
    href="{{ logoutUrl }}"
    mat-menu-item
    [analyticsAction]="{ title: 'userLoggedOut', trackedValues: {} }">
    <pearl-icon icon="logout" [size]="24" />
    Logout
  </a>
</ng-template>
<div class="app">
  <header id="header" class="navbar-header" [ngStyle]="uiService.navbarStyle()">
    <div class="left-part-header">
      <a class="navbar-brand" [routerLink]="['/']">
        <img [src]="uiService.appLogo()" (load)="collapseMenuIfNecessary()" />
      </a>
      @if (config.userRights?.otherApplications) {
        <app-switcher [applications]="config.userRights.otherApplications" />
      }
      <nav
        class="spin-menu"
        *ngIf="collapseMenu && (!uiService.isSmallDisplay() || this.config.product === 'osv')"
        [ngStyle]="uiService.navbarStyle()">
        <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
          <ng-template matMenuContent>
            <div [ngStyle]="uiService.navbarStyle()" class="menu-filling-panel">
              <div *ngFor="let panel of panels">
                <div *ngIf="canShowPanel(panel)">
                  <div [ngSwitch]="panel.type">
                    <div *ngSwitchCase="'submenu'">
                      <button
                        [matMenuTriggerFor]="submenu"
                        [ngStyle]="getPanelStyle(panel)"
                        class="spin-menu-item"
                        mat-menu-item>
                        <pearl-icon class="panel-icon" *ngIf="panel.icon" [icon]="panel.icon" [size]="24" />
                        {{ panel.menuTitle }}
                        <i *ngIf="panel.mark" class="mark"> {{ panel.mark.text }} </i>
                      </button>
                      <mat-menu #submenu>
                        <div [ngStyle]="uiService.navbarStyle()" class="menu-filling-panel">
                          <div *ngFor="let subpanel of getSubpanels(panel)">
                            <a
                              *ngIf="canShowPanel(subpanel)"
                              class="spin-menu-item"
                              [routerLink]="[getPanelRouterLink(subpanel)]"
                              [queryParams]="getPanelRouterLinkParams()"
                              (click)="reloadPage($event)"
                              [ngStyle]="getPanelStyle(subpanel)"
                              mat-menu-item>
                              <pearl-icon class="panel-icon" *ngIf="subpanel.icon" [icon]="subpanel.icon" [size]="24" />
                              {{ subpanel.menuTitle }}
                              <i *ngIf="subpanel.mark" class="mark">{{ subpanel.mark.text }}</i>
                            </a>
                          </div>
                        </div>
                      </mat-menu>
                    </div>
                    <a
                      *ngSwitchCase="'link'"
                      href="{{ getPanelLink(panel) }}"
                      target="_blank"
                      class="spin-menu-item"
                      [ngStyle]="getPanelStyle(panel)"
                      mat-menu-item>
                      <pearl-icon class="panel-icon" *ngIf="panel.icon" [icon]="panel.icon" [size]="24" />
                      {{ panel.menuTitle }}
                      <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
                    </a>
                    <a
                      *ngSwitchDefault
                      [routerLink]="[getPanelRouterLink(panel)]"
                      [queryParams]="getPanelRouterLinkParams()"
                      class="spin-menu-item"
                      (click)="reloadPage($event)"
                      [ngStyle]="getPanelStyle(panel)"
                      mat-menu-item>
                      <pearl-icon class="panel-icon" *ngIf="panel.icon" [icon]="panel.icon" [size]="24" />
                      {{ panel.menuTitle }}
                      <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-menu>

        <button
          class="hidden-menu-button"
          mat-button
          [ngClass]="{ 'menu-button-full-width': !collapseTextMenu }"
          [matMenuTriggerFor]="appMenu">
          <mat-icon>menu</mat-icon>
          <span *ngIf="!collapseTextMenu"> Menu </span>
        </button>
      </nav>
      <!-- Non collapsed menu -->
      <nav class="spin-menu" *ngIf="!collapseMenu" [ngStyle]="uiService.navbarStyle()">
        <ng-container *ngFor="let panel of panels">
          <ng-container *ngIf="canShowPanel(panel)">
            <ng-container *ngIf="panel.type == 'submenu'">
              <button [matMenuTriggerFor]="submenu" [ngStyle]="getPanelStyle(panel)" mat-button class="spin-menu-item">
                {{ panel.menuTitle }}
                <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <mat-menu #submenu [ngStyle]="uiService.navbarStyle()">
                <div [ngStyle]="uiService.navbarStyle()" class="menu-filling-panel">
                  <ng-container *ngFor="let subpanel of panel.subpanels">
                    <a
                      *ngIf="canShowPanel(subpanel)"
                      class="spin-menu-item"
                      routerLinkActive="active"
                      [routerLink]="[getPanelRouterLink(subpanel)]"
                      [queryParams]="getPanelRouterLinkParams()"
                      (click)="reloadPage($event)"
                      [ngStyle]="getPanelStyle(subpanel)"
                      mat-menu-item>
                      {{ subpanel.menuTitle }}
                      <i *ngIf="subpanel.mark" class="mark"> {{ subpanel.mark.text }} </i>
                    </a>
                  </ng-container>
                </div>
              </mat-menu>
            </ng-container>

            <a
              *ngIf="panel.type == 'link'"
              class="spin-menu-item"
              href="{{ getPanelLink(panel) }}"
              target="_blank"
              [ngStyle]="getPanelStyle(panel)"
              class="spin-menu-item"
              mat-menu-item>
              <span class="title">{{ panel.menuTitle }}</span>
              <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
            </a>

            <a
              *ngIf="panel.type != 'submenu' && panel.type != 'link'"
              routerLinkActive="active"
              [routerLink]="[getPanelRouterLink(panel)]"
              [queryParams]="getPanelRouterLinkParams()"
              class="spin-menu-item"
              (click)="reloadPage($event)"
              [ngStyle]="getPanelStyle(panel)"
              mat-menu-item>
              <span class="title">{{ panel.menuTitle }}</span>
              <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
            </a>
          </ng-container>
        </ng-container>
      </nav>
    </div>
    <div class="right-part-header">
      <div class="right-menu">
        <div class="internet-status" *ngIf="routerService.isOfflineViewActivated$ | async">
          <mat-slide-toggle
            class="internet-status-slide-toggle"
            [disabled]="!internetStatusService.internetAvailable()"
            [checked]="internetStatusService.internetUp$ | async"
            (toggleChange)="internetStatusToggleChange()" />
          <ng-container *ngIf="internetStatusService.internetUp$ | async; else internetOffline">
            <pearl-icon icon="online" [size]="24" class="internet-icon online-color" />
            <span class="online-color">Online</span>
          </ng-container>
          <ng-template #internetOffline>
            <pearl-icon icon="offline" [size]="24" class="internet-icon offline-color" />
            <span class="offline-color">Offline</span>
          </ng-template>
        </div>
        <search-bar
          #search_bar
          id="main-search-bar"
          [ngClass]="{ 'menu-search-bar-hidden': !hasSearchAccess }"
          [searchLabel]="searchLabel()"
          [highlighted]="true"
          [hasLabel]="false"
          [searchItemsAvailable]="config.searchItemsAvailable"
          [recentSearch]="searchItems"
          [clearAfterSelect]="true"
          source="main"
          (onoptionselected)="optionSelected($event)"
          [resultTypeOrder]="config.searchBarResultOrder"
          [canRequestVessel]="canRequestNewVessel()" />
        <button
          matTooltip="My fleet"
          mat-icon-button
          *ngIf="!uiService.isSmallDisplay() && config.vesselFleetVisible"
          (click)="sideMenuToggle(SideMenuEnum.Fleet)"
          [ngClass]="{ 'side-menu-open': openedSideMenu === SideMenuEnum.Fleet || config.selectedFleets?.length }">
          <mat-icon
            matBadge="{{ config.selectedFleets?.length }}"
            matBadgeSize="small"
            [matBadgeHidden]="!config.selectedFleets?.length"
            matBadgeColor="accent">
            directions_boat</mat-icon
          >
        </button>
        <button
          matTooltip="Projects"
          mat-icon-button
          *ngIf="!uiService.isSmallDisplay() && hasOsvProjects()"
          (click)="sideMenuToggle(SideMenuEnum.Project)"
          [ngClass]="{ 'side-menu-open': openedSideMenu === SideMenuEnum.Project }">
          <mat-icon
            matBadge="{{ getNumberSelectedOsvProjects() }}"
            matBadgeSize="small"
            [matBadgeHidden]="!config.selectedOsvProjectIds?.length"
            matBadgeColor="accent">
            dashboard
          </mat-icon>
        </button>
        <button
          matTooltip="Bookmarks"
          class="bookmark-button"
          mat-icon-button
          *ngIf="!uiService.isSmallDisplay()"
          (click)="sideMenuToggle(SideMenuEnum.Bookmark)">
          <mat-icon> {{ openedSideMenu === SideMenuEnum.Bookmark ? "bookmark" : "bookmark_border" }}</mat-icon>
        </button>
        <button
          matTooltip="Reports & Alerts"
          routerLink="/dashboard/global-alert-subscription"
          mat-icon-button
          *ngIf="!uiService.isSmallDisplay()">
          <mat-icon>notifications</mat-icon>
        </button>
        <button
          matTooltip="Resources"
          routerLink="/dashboard/resource-gallery"
          mat-icon-button
          *ngIf="config.userRights?.hasResources && !uiService.isSmallDisplay()">
          <mat-icon>library_books</mat-icon>
          <i *ngIf="!config.isMarketIntel" class="mark">NEW</i>
        </button>
        <button
          mat-icon-button
          matTooltip="Time zone"
          [matMenuTriggerFor]="timezoneMenu"
          [disabled]="timezoneService.disabled"
          *ngIf="config.appConfig?.timezoneSelector && !uiService.isSmallDisplay()"
          [ngClass]="{ active: !timezoneService.disabled && timezoneService.localTimezone != 'local' }">
          <mat-icon>schedule</mat-icon>
        </button>
        <button
          matTooltip="Database overview"
          routerLink="/dashboard/database-overview"
          mat-icon-button
          *ngIf="hasDatabaseOverviewAccess() && !uiService.isSmallDisplay()">
          <mat-icon>settings</mat-icon>
        </button>
        <button
          *ngIf="!uiService.isSmallDisplay(); else smallDisplayMenu"
          id="person-menu"
          mat-icon-button
          [matMenuTriggerFor]="rightMenu">
          <mat-icon>person</mat-icon>
        </button>
        <ng-template #smallDisplayMenu>
          <button
            id="person-small-display-menu"
            mat-icon-button
            [matMenuTriggerFor]="smallDisplayRightMenu"
            class="person-small-display-menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-template>

        <mat-menu #smallDisplayRightMenu class="spin-menu-item">
          <div [ngStyle]="uiService.navbarStyle()" class="menu-filling-panel">
            <ng-container *ngTemplateOutlet="logout"></ng-container>

            <button
              mat-menu-item
              id="buttonAsAction"
              routerLink="/dashboard/resource-gallery"
              *ngIf="config.userRights?.hasResources">
              <mat-icon>library_books</mat-icon>
              Resources
            </button>
            <button
              mat-menu-item
              id="buttonAsAction"
              (click)="sideMenuToggle(SideMenuEnum.Fleet)"
              [ngClass]="{ 'side-menu-open': openedSideMenu === SideMenuEnum.Fleet || config.selectedFleets?.length }"
              *ngIf="config.vesselFleetVisible">
              <mat-icon
                matBadge="{{ config.selectedFleets?.length }}"
                matBadgeSize="small"
                [matBadgeHidden]="!config.selectedFleets?.length"
                matBadgeColor="accent">
                directions_boat</mat-icon
              >
              My fleet
            </button>
            <button
              mat-menu-item
              id="buttonAsAction"
              (click)="sideMenuToggle(SideMenuEnum.Project)"
              [ngClass]="{ 'side-menu-open': openedSideMenu === SideMenuEnum.Project }"
              *ngIf="config?.userInfo?.accessibleOsvProjects?.length > 1">
              <mat-icon
                matBadge="{{ getNumberSelectedOsvProjects() }}"
                matBadgeSize="small"
                [matBadgeHidden]="!config.selectedOsvProjectIds?.length"
                matBadgeColor="accent">
                dashboard</mat-icon
              >
              Projects
            </button>
          </div>
        </mat-menu>
        <mat-menu #rightMenu class="spin-menu-item">
          <div [ngStyle]="uiService.navbarStyle()" class="menu-filling-panel">
            <ng-container *ngTemplateOutlet="logout"></ng-container>
            <a
              *ngFor="let builtInPanel of rightMenuDashboards"
              [routerLink]="[getPanelRouterLink(builtInPanel)]"
              [queryParams]="getPanelRouterLinkParams()"
              class="spin-menu-item"
              (click)="reloadPage($event)"
              mat-menu-item
              [ngStyle]="getPanelStyle(builtInPanel)">
              <pearl-icon
                class="spin-menu-item"
                [icon]="builtInPanel.icon ? builtInPanel.icon : 'settings'"
                [size]="24" />
              {{ builtInPanel.menuTitle }}
              <i *ngIf="builtInPanel.mark" class="mark">{{ builtInPanel.mark.text }}</i>
            </a>
            <a *ngIf="hasApiDocs" target="_blank" [href]="apiDocsUrl" mat-menu-item class="spin-menu-item">
              <pearl-icon icon="api" [size]="24" />
              API Docs
            </a>
            <!-- By default, clicking on a menu item closes the menu. But here, we want it to stay open when we click
                 in the search bar. We still want the event to propagate outside the search bar by default so all search
                bar close when we have many on a page. This fix is linked to this specific behavior. -->
            <div
              *ngIf="canImpersonate && !isImpersonate"
              class="impersonate right-menu"
              (click)="$event.stopPropagation()">
              <search-bar
                [searchItemsAvailable]="usersForImpersonation"
                searchLabel="type user..."
                source="impersonate"
                [clearAfterSelect]="true"
                (onoptionselected)="impersonate($event)" />
            </div>
          </div>
        </mat-menu>
        <mat-menu #timezoneMenu class="spin-menu-item">
          <div [ngStyle]="uiService.navbarStyle()" class="menu-filling-panel">
            <a *ngFor="let tz of timezones" class="spin-menu-item" (click)="setTimezone(tz.code, $event)" mat-menu-item>
              @if (tz.code == timezoneService.localTimezone) {
                <pearl-icon icon="checked" [size]="24" />
              }
              {{ tz.title }}
            </a>
            <div class="impersonate right-menu">
              <!-- Change by an input like in entity-detail-field -->
              <search-bar
                [searchItemsAvailable]="availableTimezones"
                searchLabel="Custom timezone..."
                [clearAfterSelect]="true"
                (onoptionselected)="appendTimezone($event)"
                (click)="$event.stopPropagation()" />
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </header>

  <mat-drawer-container [hasBackdrop]="false" class="dashboard-container">
    <mat-drawer
      class="right-sidebar"
      #sideDrawer
      [mode]="'over'"
      [position]="'end'"
      *ngIf="true"
      (openedChange)="bookmarkStateChange($event)">
      <!-- we use [hidden] on the component below, so that when the sidebar is collapsed, it's still reachable. -->
      <vessel-fleet-sidebar
        *ngIf="config.vesselFleetVisible && fleetSidebarLoaded && config.specsLoadedPromise | async"
        [hidden]="openedSideMenu !== SideMenuEnum.Fleet"
        #vesselFleetSidebar
        (vesselFleetSelected)="onVesselFleetSelected($event)"
        (mobileCloseVesselFleetSidebar)="sideMenuToggle(SideMenuEnum.Fleet)" />
      <project-sidebar
        *ngIf="hasOsvProjects()"
        [hidden]="openedSideMenu !== SideMenuEnum.Project"
        (osvProjectSelected)="onProjectScopeSelect($event)"
        (osvProjectApplied)="closeAndApply()"
        (mobileCloseProjectSidebar)="sideMenuToggle(SideMenuEnum.Project)" />
      <bookmarker
        *ngIf="config.loaded"
        [hidden]="openedSideMenu !== SideMenuEnum.Bookmark"
        #bookmarks
        [currentDashboard]="selectedPanelTitle"
        (onBookmarkSelect)="onBookmarkSelect($event)" />
    </mat-drawer>
    <mat-drawer-content>
      <button
        [ngClass]="{ 'save-analysis-hidden': !displaySaveAnalysis || userHideSaveAnalysis }"
        class="save-analysis-button"
        mat-raised-button
        (click)="saveAnalysis()">
        <mat-icon>bookmark</mat-icon> Save current analysis
        <button class="save-analysis-close" mat-icon-button (click)="hideSaveAnalysisClick($event)">
          <mat-icon>close</mat-icon>
        </button>
      </button>

      <router-outlet />

      <div *ngIf="!(config.specsLoadedPromise | async)" class="app-loading-container">
        <div class="spinning-logo"></div>
        <div class="app-loading-text">Preparing application <span>.</span><span>.</span><span>.</span></div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>

  <footer id="footer" [ngStyle]="uiService.navbarStyle()" class="navbar">
    <div class="container-fluid">
      <p class="footer-text">&copy; Spinergie - All rights reserved</p>
      <p class="footer-text" *ngIf="!uiService.isSmallDisplay()">{{ bottomText }}</p>
      <p class="footer-text">{{ webAppVersion }}</p>
    </div>
  </footer>
</div>

<div
  *ngIf="impersonatingUser"
  class="impersonation"
  [ngStyle]="impersonationStyle"
  (click)="showHideImpersonation(true, $event)">
  You are viewing Spinergie as {{ impersonatingUser }}, except private data
  <span *ngIf="!userActivated">(Deactivated)</span>
  <div class="impersonation-buttons">
    <div (click)="endImpersonation()">Quit</div>
    <div (click)="showHideImpersonation(false, $event)">Hide</div>
  </div>
</div>

<div *ngIf="appLoaded">
  <div *ngIf="isInDevMode()" class="dev-toolbox">
    <mat-expansion-panel
      class="dev-toolbox-expansion-panel"
      (opened)="expandDevToolbox(true)"
      (closed)="expandDevToolbox(false)"
      [expanded]="canExpandDevToolbox()">
      <mat-expansion-panel-header collapsedHeight="25px">
        <mat-panel-title>
          <pearl-icon icon="menu" [size]="24" />
          Dev tools
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="dev-toolbox-cell">
        <mat-slide-toggle [checked]="getVesselAutoReloadState()" (toggleChange)="updateVesselAutoreloadState()" />
        Vessel Autoreload
      </div>
      <div class="dev-toolbox-cell">
        <mat-slide-toggle [checked]="getInternetAutoReloadState()" (toggleChange)="updateInternetAutoreloadState()" />
        Internet Autoreload
      </div>
      <div class="dev-toolbox-cell">
        <mat-slide-toggle [checked]="getDevSetting('mocksActive')" (toggleChange)="toggleDevSetting('mocksActive')" />
        Mocks active
      </div>
      <div class="dev-toolbox-cell">
        <button mat-icon-button color="primary" (click)="reloadPageConfig()" aria-label="refresh">
          <mat-icon>refresh</mat-icon>
        </button>
        Refresh config
      </div>
    </mat-expansion-panel>
  </div>
</div>

<!-- This simple-tooltip is used all over the App thanks to the SimpleTooltipService -->
<simple-tooltip />
