import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  /** Route handles that have been stored. Component have only been detached and not destroyed. */
  private detachedRoutes: { [key: string]: DetachedRouteHandle } = {};
  /** Flag that tells that the current route should not be reused and not be detached (then destroyed). */
  private currentRouteRecreation = false;

  /**
   * If this returns false, the component is destroyed, otherwise it is detached and stored to be attached back.
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    /** In this case, we want the component not to be detached but destroyed. */
    if (this.isRouteToRecreate(route)) {
      this.currentRouteRecreation = false; // reset
      return false;
    }

    return true;
  }

  /**
   * Route should be recreated if the flag currentRouteRecreation is set and the route is an ending node.
   * We don't want to apply this behavior on "/" and "dashboard/" routes, because they never change, that why we only
   * apply to ending nodes (nodes without children).
   */
  private isRouteToRecreate(route: ActivatedRouteSnapshot): boolean {
    return this.currentRouteRecreation && route.children.length === 0;
  }

  /** Stores the detached route. Only called if shouldDetach(route) is true. */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.detachedRoutes[route?.routeConfig.path] = handle;
  }

  /** Return true if the current route can be retrieved from detachedRoutes and attached again. */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.detachedRoutes[route?.routeConfig?.path] != null;
  }

  /** Retrieve the detached route. Only called if shouldAttach(route) is true. */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.detachedRoutes[route.routeConfig.path];
  }

  /**
   * If true, would reuse current Route, no detach and attach stuff would occur.
   * If false, component would be either created or attached back.
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    /** We want to force not reusing current route, even if it is the same. */
    if (this.isRouteToRecreate(current)) return false;

    return future.routeConfig === current.routeConfig;
  }

  /** All detached routes would be recreated when navigating to them. */
  public clearDetachedRoutes(): void {
    this.detachedRoutes = {};
  }

  public recreateCurrentRoute(): void {
    this.currentRouteRecreation = true;
  }
}
