<div data-testid="fleet-sidebar">
  <div class="sidebar-header">
    <button
      class="small-display-close-button"
      mat-flat-button
      *ngIf="uiService.isSmallDisplay()"
      (click)="closeVesselFleetSidebar()">
      <mat-icon class="header-button" mat-flat-button>close</mat-icon>
    </button>
    <div class="title">
      My Fleets
      <div class="hint">
        Hold <kbd>⌘</kbd> or <kbd>Ctrl</kbd> <br />
        to add to selection
      </div>
    </div>

    <div class="search-and-save">
      <pearl-form-field label="Search ...">
        <input matInput [(ngModel)]="searchText" autocomplete="off" />
      </pearl-form-field>
      <div class="header-buttons">
        <!-- Following div is used so that the tooltip is shown when the button is disabled -->
        <div
          [spinTooltip]="
            createFleetDisabled ? 'You cannot create a vessel fleet if you are already applying a fleet' : null
          ">
          <button
            class="header-button"
            mat-flat-button
            *ngIf="!uiService.isSmallDisplay()"
            (click)="createVesselFleet()"
            [disabled]="createFleetDisabled">
            <mat-icon>add</mat-icon> Create new fleet
          </button>
        </div>
        <button
          *ngIf="config.selectedFleets?.length"
          class="leave-all-fleet-button"
          mat-flat-button
          (click)="leaveAllFleet()">
          <mat-icon>logout</mat-icon> Return to main fleet
        </button>
      </div>
    </div>
  </div>
  <div id="top"></div>
  <div class="sidebar-list" [ngClass]="{ selected: config.selectedFleets }">
    <vessel-fleet-list
      [vesselFleets]="sharedVesselFleets"
      [listTitle]="'Shared fleets'"
      [searchText]="searchText"
      [displayTitle]="sharedVesselFleets.length > 0"
      [vesselDataset]="vesselDataset"
      [canEdit]="config.canShareFleet()"
      (onselect)="onSelectVesselFleet()"
      (shareFleet)="updateListAfterShared($event)">
    </vessel-fleet-list>
    <vessel-fleet-list
      [vesselFleets]="vesselFleets"
      [listTitle]="'My fleets'"
      [searchText]="searchText"
      [displayTitle]="sharedVesselFleets.length > 0"
      [vesselDataset]="vesselDataset"
      [canEdit]="true"
      (onselect)="onSelectVesselFleet()"
      (shareFleet)="updateListAfterShared($event)">
    </vessel-fleet-list>
  </div>
</div>
