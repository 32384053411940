import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation, effect, inject,
  signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, RouteReuseStrategy, Router } from '@angular/router';

import { datadogLogs } from '@datadog/browser-logs';
import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import './day-js-setup';
import { sum } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import { CaptureConsole } from '@sentry/integrations';

import { AppRoutes } from './routes';
import { Config } from '../config/config';
import { Color, DateTimezone, IdentityItem, MenuItem, NavigationExtraState, SubMenuItem,
  VesselFleetInfo } from '../helpers/types';
import { NavigationHelper } from '../helpers/navigation-helper';
import { AppInfoService, PreferencesKeys } from './app-info-service';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { Bookmark, VesselFleet } from '../user-saving/user-saving-types';
import { BookmarkerComponent } from '../user-saving/bookmarker';
import { VesselFleetSidebarComponent } from '../user-saving/vessel-fleet-sidebar';
import { textWidth } from '../helpers/d3-helpers';
import { DataLoader } from '../data-loader/data-loader';
import { InternetStatusService } from '../helpers/internet-status.service';
import { RouterService } from '../helpers/router.service';
import { TimezoneService } from '../helpers/timezone.service';
import { SearchBarComponent } from '../shared/search-bar';
import { DialogManager } from '../database/dialog-manager';
import { ReportingOfflineDatabaseService } from '../database/local/reporting-offline-database.service';
import { DatabaseHelper } from '../database/database-helper';
import { ErrorService } from '../error-pages/error.service';
import { ServiceWorkerStateService } from './service-worker-state.service';
import { DateHelper } from '../helpers/date-helper';
import { IntercomService } from '../shared/services/intercom.service';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
// content is changed at build time
import appInfo from './app-info';
import { UIService } from '../shared/services/ui.service';
import { SimpleTooltipComponent } from '../shared/simple-tooltip';
import { SimpleTooltipService } from '../shared/services/simple-tooltip.service';
import { Dashboard } from '../dashboards/model/dashboard';
import { RefDataProvider } from '../data-loader/ref-data-provider';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';
import { PEARL_ICON_NAMES, UNIQUE_SIZE_ICONS } from '../shared/pearl-components';

enum SideMenu {
  Closed = null,
  Bookmark = 1,
  Fleet = 2,
  Project = 3,
}

const IGNORED_EMAILS = new Set(['document_movement_map_fetcher.bot@spinergie.com']);
const EMPTY_ARRAY = [];

@Component({
  selector: 'spin-app',
  templateUrl: 'app.html',
  styleUrls: ['app.scss'],
  // View encapsulation removed because of styling .mat-form-field and wrapper
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('search_bar', { static: false })
  $searchBar: SearchBarComponent;
  @ViewChild('sideDrawer', { static: false })
  $sideDrawer: MatDrawer;
  @ViewChild('bookmarks', { static: false })
  $bookmarks: BookmarkerComponent;
  @ViewChild('vesselFleetSidebar', { static: false })
  $vesselFleetSidebar: VesselFleetSidebarComponent;
  @ViewChild(SimpleTooltipComponent)
  $simpleTooltipComponent: SimpleTooltipComponent;
  @ViewChild(Dashboard)
  dashboard: Dashboard<any>;

  // needed to use the enum in template
  public readonly SideMenuEnum = SideMenu;
  private cdRef = inject(ChangeDetectorRef);
  private ngZone = inject(NgZone);
  private router = inject(Router);
  public routeReuseStrategy = inject(RouteReuseStrategy) as CustomRouteReuseStrategy;
  private zone = inject(NgZone);
  private appInfoService = inject(AppInfoService);
  private errorService = inject(ErrorService);
  private dataLoader = inject(DataLoader);
  private dialogManager = inject(DialogManager);
  private matIconRegistry = inject(MatIconRegistry);
  private domSanitizer = inject(DomSanitizer);
  /** Handle the communication with the service worker */
  private reportingOfflineDatabaseService = inject(ReportingOfflineDatabaseService);
  private serviceWorkerStateService = inject(ServiceWorkerStateService);
  private productAnalyticsService = inject(ProductAnalyticsService);

  public internetStatusService = inject(InternetStatusService);
  public routerService = inject(RouterService);
  public timezoneService = inject(TimezoneService);

  public intercomService = inject(IntercomService);
  public analyticsService = inject(ProductAnalyticsService);
  public simpleTooltipService = inject(SimpleTooltipService);

  public panels: MenuItem[] = [];
  public rightMenuDashboards: MenuItem[];
  public selectedPanel: string = '';
  public selectedPanelTitle: string = '';

  public searchLabel = signal<string>('');
  public collapseMenu: boolean;
  public collapseTextMenu: boolean;
  public bottomText: string;
  public showImpersonation: boolean = true;
  public hasSearchAccess: boolean = false;
  public canImpersonate: boolean = false;
  public isImpersonate: boolean = false;
  public usersForImpersonation: IdentityItem[] = null;

  public uiService = inject(UIService);

  public openedSideMenu: SideMenu = SideMenu.Closed;
  /** This flag allows to lazy load fleet sidebar. */
  public fleetSidebarLoaded = null;

  // boolean use to know if we have to display the save analysis button
  public displaySaveAnalysis: boolean = false;

  /*
   * if user decides to manually hide this button we shouldn't display it
   * even if display save analysis is true
   */
  public userHideSaveAnalysis: boolean = false;

  public createNewBookmark: boolean = false;
  public appLoaded = false;
  public hasOsvProjectChanged: boolean = false;
  protected getPanelRouterLinkParams = NavigationHelper.getQueryParamsFromSessionStorage;

  private readonly sink$: Subscription[] = [];

  /*
   * Timezones
   * Start with only local (default) & UTC (utc)
   * Add other timezones from DPR config (if enabled)
   */
  public timezones: { title: string; code: string }[] = [
    { title: 'Local timezone', code: 'local' },
    { title: 'UTC+0', code: 'utc' },
  ];
  /** Available timezone inside search bar */
  public availableTimezones = [];

  /**
   * @constructor
   *
   * @param  {Config}   config    App config
   * @param  {Injector} injector  Injector
   */
  constructor(public config: Config) {
    // Timezone (root)
    this.timezoneService.name = 'app';

    this.registerPearlIcons();
    config.searchItemsReceived.subscribe(received => {
      if (!received) {
        return;
      }
      this.searchLabel.set(config.searchLabel);
      this.hasSearchAccess = this.getUserSearchAccess();
      if (this.cdRef) {
        this.cdRef.detectChanges();
      }
    });
    this.collapseMenu = false;
    this.collapseTextMenu = false;
    this.router.resetConfig(AppRoutes);
    this.router.events
      .pipe(filter(value => value instanceof NavigationEnd))
      .subscribe((_: NavigationEnd) => {
        /*
         * We can receive a navigation event very early. (during the initial loading)
         * while the config has not yet been loaded. In this case we can skip the logic
         */
        if (!this.config.appConfig) {
          return;
        }

        // always append __selectedProjectIds in URL when navigating on SFM
        if (this.config.product === 'osv' && this.config.selectedOsvProjectIds) {
          NavigationHelper.serializeToCurrentUrl({
            [Config.SELECTED_PROJECTS]: this.config.selectedOsvProjectIds.toString(),
          });
        }

        const matchRouterUrl = this.router.url.match(/dashboard\/([-\w]+)/);
        if (!matchRouterUrl) {
          return;
        }

        this.selectedPanel = matchRouterUrl[1];
        if (this.selectedPanel === 'page') {
          this.selectedPanel = this.router.url.match(NavigationHelper.PAGE_REGEXP)[1];
        }

        // timezone service forced to report timezone in reporting related dashboards
        if (this.selectedPanel === 'dpr') {
          this.timezoneService.disabled = true;
        } else if (this.timezoneService.disabled) {
          this.timezoneService.restoreUserTimezone();
        }

        this.setCurrentPanelTitle();
      });

    effect(() => {
      this.intercomService.updateIntercomOptions({ hideDefaultLauncher: this.uiService.isSmallDisplay() });
      if (this.appLoaded && this.uiService.isSmallDisplay()) {
        this.closeSideMenu();
      }
    });

    /*
     * Helper function which allows to activate the front dev mode in production
     * or in spin-test.
     */
    window['activateSpinDevMode'] = value => {
      this.config.userInfo.isDevMode = value;
    };
  }

  /**
   * On init
   * Load config and prepare all components
   *
   * @return {void}
   */
  ngOnInit(): void {
    /*
     * The app is being initialized, we issue config loading
     * and the shell of the application will show as soon as config is loaded
     */
    this.config.load().then(() => {
      // start checking for server connectivity (allows offline apps) once we know the current project
      this.updateInternetStatusServiceChecking();
      this.reportingOfflineDatabaseService.setupDb(this.config.impersonate ?? this.config.userInfo?.spinergieUserEmail)
        .then(
          () => {
            /**
             * Deleting a user's local tables can happen in two ways:
             *  - In a demo envs to avoid report id conflict due to previous demos
             *  - If user has been flagged manually with shouldResetOfflineDb (user's local db is in an unstable state)
             */
            if (this.config.appParams.env.includes('demo') || this.config.userInfo?.shouldResetOfflineDb) {
              if (this.config.userInfo?.shouldResetOfflineDb) {
                this.reportingOfflineDatabaseService.logDbSnapshot();
              }
              this.reportingOfflineDatabaseService.resetDb();
            }
          },
        );

      this.initHeader();

      this.bottomText = this.config.appConfig?.bottomText || '';
      window['isMapV2'] = this.config.hasFeature('map-v2', { bypassForAdmin: false });
      if (!window['isMapV2']) {
        const gmapsKeys = this.config.appParams.gmapsKey;
        this.loadGmaps(gmapsKeys);
      }
      this.enableRum(this.config.appParams.datadogToken);
      if (this.intercomService.isEnabled) {
        this.intercomService.activate();
        // We update the intercomOptions based on isSmallDisplay
        this.intercomService.updateIntercomOptions({ hideDefaultLauncher: this.uiService.isSmallDisplay() });
      }
      if (this.analyticsService.isEnabled) {
        this.analyticsService.activate();
      }
      this.enableSentry();

      this.canImpersonate = this.config.canImpersonate;
      // TODO: check which endpoints that are not useful for offline app that can be put here
      if (this.internetStatusService.isOnline()) {
        this.config.getUsersAndGroups().then(() => {
          this.usersForImpersonation = this.config.usersAndGroups.filter(d => d.type === 'user');
        });
      }
      this.isImpersonate = !!this.config.impersonate;
      this.hasSearchAccess = this.getUserSearchAccess();
      this.appInfoService.displaySaveAnalysis.subscribe(display => {
        this.displaySaveAnalysis = !this.config.skipSaveAnalysis && display;
        this.cdRef.detectChanges();
      });
      this.appInfoService.vesselFleetCreate.subscribe(vesselFleet => {
        this.createNewVesselFleet(vesselFleet);
      });
      this.appInfoService.enterLastFleetCreated.subscribe(() => this.enterLastCreateFleet());

      /* Attach tooltip component to SimpleTooltipService */
      this.simpleTooltipService.component = this.$simpleTooltipComponent;

      // Set default app timezone
      this.timezoneService.timezoneConfig = { timezone: this.config.getDateTimezone() };
      this.timezoneService.setLocalTimezone('local');

      // Retrieve timezones defined in DPR config (if enabled)
      this.config.getDefaultTimezones()
        .then(dprTimezones => {
          this.timezones = this.timezones.concat(dprTimezones);
        })
        .then(() => {
          // Override default app timezone by user preference timezone if any
          const userPreferencesTimezone = this.appInfoService.getUserPreference(PreferencesKeys.timezone);
          if (typeof userPreferencesTimezone === 'string') {
            // Add user preferences timezone to the list if not yet present
            if (!this.timezones.find(tz => tz.code === userPreferencesTimezone)) {
              this.appendTimezone(DateHelper.getIdentityItemTimezone(userPreferencesTimezone));
            }

            this.timezoneService.setLocalTimezone(userPreferencesTimezone);
          }

          // Subscribe to timezone changes to save to user preferences
          this.sink$.push(this.timezoneService.onChange.subscribe((timezone: DateTimezone) => {
            if (timezone !== this.appInfoService.getUserPreference(PreferencesKeys.timezone)) {
              this.appInfoService.saveUserPreference(PreferencesKeys.timezone, timezone);
            }
          }));
        });

      /**
       * Define available timezones
       * Better to set and update them only when needed, than having a function calling getIdentityItemOfficialTimezones
       */
      this.availableTimezones = DateHelper.getIdentityItemOfficialTimezones(this.timezones.map(tz => {
        return { title: tz.code } as IdentityItem;
      }));
      this.appLoaded = true;
    }).catch(_ => {
      this.dialogManager.showMessage(
        'Something went wrong, please try reloading the page.',
        'error',
      );
    });
  }

  /**
   * On destroy, normally is called when the app is closed
   */
  ngOnDestroy(): void {
    this.sink$.forEach(subscription => subscription.unsubscribe());
  }

  private registerPearlIcons(): void {
    for (const icon of PEARL_ICON_NAMES) {
      let absoluteIconPath = '';
      const iconName = icon.split('.')[0];

      if (UNIQUE_SIZE_ICONS.includes(iconName)) {
        absoluteIconPath = `${iconName}_unique_size/${icon}`;
      } else {
        const iconPath: string[] = icon.split('_');
        const iconSize = iconPath.pop();
        absoluteIconPath = iconPath.join('_') + '/' + iconSize;
      }

      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `/assets/icons/${absoluteIconPath}`,
        ),
      );
    }
  }

  private preparePanels(panels: MenuItem[]): MenuItem[] {
    for (const panel of panels) {
      panel.menuTitle = this.panelTitle(panel);
      if (panel.type === 'submenu') {
        for (const subpanel of panel.subpanels) {
          subpanel.menuTitle = this.panelTitle(subpanel);
        }
      }
    }
    return panels;
  }

  /**
   * Initialize header content related variables
   *  - collapse variables set to default values
   *  - set the logo src
   *  - Generate panels for menus
   *  - bind functions to resize window event
   *  - check if we need to collapse the menu
   */
  private initHeader(): void {
    if (this.config.appParams.logoUrl) {
      this.uiService.customLogo.set(this.config.appParams.logoUrl);
    }

    if (this.config.appParams.navbar) {
      this.uiService.navbarColor.set(this.config.appParams.navbar);
    }

    // collapsing the menu during the loading, otherwise the header size is not well computed
    this.collapseMenu = true;
    this.collapseTextMenu = true;

    this.rightMenuDashboards = this.preparePanels(this.config.appConfig.rightMenuDashboards);

    this.panels = this.preparePanels(this.config.appConfig.panels);

    let panelName = this.router.url.match(/dashboard\/([-\w]+)/);
    if (panelName && panelName[1] === 'page') {
      panelName = this.router.url.match(NavigationHelper.PAGE_REGEXP);
    }
    this.selectedPanel = panelName ? panelName[1] : null;

    this.searchLabel.set(this.config.searchLabel);

    this.zone.runOutsideAngular(() => window.addEventListener('resize', () => this.collapseMenuIfNecessary()));
    this.cdRef.detectChanges();
    this.collapseMenuIfNecessary();
  }

  /**
   * Loading gmaps in async way depending on the config:
   *  - creates a callback function available on the window (global level)
   *  - injects a gmaps loading script tag passing the created function as callback parameter
   *  - once Gmaps are loaded set the componentn variable to true and detect changes to realod the UI
   * @param gmapsKeys
   */
  public loadGmaps(gmapsKeys: string): void {
    window['afterGmapsLoaded'] = (): void => {
      this.config.gmapsLoadedResolve(true);
    };

    const keyPart = gmapsKeys ? `&key=${gmapsKeys}` : '';
    const scriptUrl = `https://maps.googleapis.com/maps/api/js?
      v=3.55.7${keyPart}&libraries=geometry,drawing,places&callback=afterGmapsLoaded`;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptUrl;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  public updateInternetStatusServiceChecking(): void {
    if (this.config.getInternetAutoReloadState()) {
      this.internetStatusService.startChecking(this.config.product);
      return;
    }
    this.internetStatusService.stopChecking();
  }

  public getUserSearchAccess(): boolean {
    if (!this.config.searchItemsAvailable || !this.config.searchItemsAvailable.length) {
      return false;
    }
    return this.config.isFullAdmin || Object.keys(this.config.dashboards).some(d => d.startsWith('page'));
  }

  /**
   *  Start DataDog session with Spinergie app ID and secret token. Two DD listeners are activated:
   *  - Logs & errors
   *  - Sessions Real time monitoring (with replay)
   *
   *  3 global settings are important:
   *  - service is the name of the project according to spindevops nomenclature (`pipelay`, `osv_rwe`, ...)
   *  - env (prod, preprod, test3, ...)
   *  - version is changed at build time, taking the last commit hash for now.
   * Returns the current session ID
   */
  private enableRum(token?: string): void {
    if (!token) return;
    if (IGNORED_EMAILS.has(this.config.userInfo?.spinergieUserEmail)) return;
    const sampleRate = this.rumDisabled ? 0 : 100;
    datadogRum.init({
      applicationId: '342b8c5f-4e01-4c5e-92a6-f85ac7f48995',
      clientToken: token,
      site: 'datadoghq.eu',
      proxy: '/spindjango/tunnel-datadog',
      service: this.config.appParams.project,
      version: appInfo.version,
      env: this.config.appParams.env,
      allowedTracingUrls: [/https:\/\/.*\.spinergie\.com/],
      traceSampleRate: 100,
      sessionSampleRate: sampleRate,
      sessionReplaySampleRate: sampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
    });
    datadogLogs.init({
      clientToken: token,
      site: 'datadoghq.eu',
      proxy: '/spindjango/tunnel-datadog',
      service: this.config.appParams.project,
      env: this.config.appParams.env,
      version: appInfo.version,
      forwardErrorsToLogs: true,
      sessionSampleRate: sampleRate,
      beforeSend: log => {
        // discard logs from offline connection
        if (log.http && log.error?.origin === 'network') {
          return false;
        }
      },
    });
    datadogRum.setUser({
      id: `${this.config.userInfo?.spinergieUserId}`,
      name: this.config.userInfo?.spinergieUser,
    });
    if (this.config.impersonate) {
      datadogRum.setGlobalContextProperty('impersonated', this.config.impersonate);
    }
    datadogRum.startSessionReplayRecording();
  }

  private enableSentry(): void {
    if (!this.config.appParams.sentryDsn) return;
    if (IGNORED_EMAILS.has(this.config.userInfo.spinergieUserEmail)) return;
    let version = appInfo.version;
    const env = this.config.appParams.env;
    if (env.includes('test')) {
      version = 'test-version'; // hardcoded to avoid regression
    }

    Sentry.init({
      dsn: this.config.appParams.sentryDsn,
      tunnel: '/spindjango/tunnel-sentry',
      // We use the fingerprint to group errors thrown
      beforeSend: (event: Sentry.Event, hint: Sentry.EventHint) => {
        const exception = hint.originalException;
        let customFingerprint;
        try {
          customFingerprint = this.getCustomFingerprint(exception);
        } catch {
          customFingerprint = null;
        }

        if (exception instanceof ErrorWithFingerprint) {
          event.fingerprint = exception.fingerprint;
        } else if (customFingerprint) {
          event.fingerprint = customFingerprint;
        }
        return event;
      },

      integrations: [new CaptureConsole({ levels: ['error'] })],
      /*
       * integrations: [
       *   // It allows to capture traces (throughput and latency) and impact of errors on the app
       *   new Sentry.Replay({
       *     maskAllText: false,
       *     blockAllMedia: true,
       *     _experiments: {
       *       mutationBreadcrumbLimit: 100000,
       *       mutationLimit: 100000,
       *     },
       *   }),
       * ],
       * right now, disable in favor of Datadog
       * Otherwise, ff RUM disabled, never get sessions, except when errors happen (see next config line)
       */
      ignoreErrors: [
        'Non-Error exception captured',
        'Http failure response',
        'ResizeObserver loop',
        'Network request failed',
        'NetworkError',
        "Cannot read properties of null (reading 'zoom')",
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      environment: env,
      release: version,
      tracesSampleRate: 0,
      dist: version,
      // always attach stack trace, even when the message is not an exception
      attachStacktrace: true,
      enabled: true,
      initialScope: {
        tags: {
          service: this.config.appParams.project,
          product: this.config.product,
        },
        user: {
          id: this.config.userInfo.spinergieUserId,
          username: this.config.userInfo.spinergieUser,
          email: this.config.userInfo.spinergieUserEmail,
        },
      },
      maxValueLength: 1000, // default is 250 which results in truncated url too frequently
    });
    if (this.config.impersonate) {
      Sentry.setContext('impersonated', {
        email: this.config.impersonate,
      });
    }
    Sentry.addGlobalEventProcessor(function(event, _hint) {
      const ddSessionId = datadogRum.getInternalContext()?.session_id;
      if (ddSessionId) { // there is no session when RUM is not enabled
        event.contexts['datadog'] = {
          sessionId: ddSessionId,
          replayUrl: `https://app.datadoghq.eu/rum/replay/sessions/${ddSessionId}`,
        };
      }
      return event;
    });
  }

  /**
   * Allow to add a fingerprint to an exception after its creation regardless of its origin based on the exception
   * itself. This should be the last resort in case we can't add the fingerprint at the right place in the code
   * (external lib, too many possible sources etc.).
   * Don't use it unless you have no other choice!
   */
  private getCustomFingerprint(exception: any): null | string[] {
    if (!exception || !exception.message || typeof exception.message !== 'string') {
      return null;
    }

    if (exception.message.includes('Http failure during parsing for')) {
      /*
       * This is to put all errors due to incomplete HTTP responses into the same Sentry issue so we can handle them
       * all easily.
       */
      return ['http-failure-during-parsing-response'];
    }

    return null;
  }

  public getPanelType(panel: MenuItem): string {
    if (!panel.type || panel.type === 'dashboard') {
      return 'dashboard';
    }
    return 'dashboard/' + panel.type;
  }

  public getPanelLink(panel: MenuItem): undefined | string {
    if (panel.type !== 'link') {
      return;
    }
    return panel.ref;
  }

  public getSubpanels(panel: MenuItem): MenuItem[] {
    return (panel as SubMenuItem).subpanels;
  }

  public getPanelRouterLink(panel: MenuItem): string {
    if ('url' in panel && panel.url) {
      return `/dashboard/${panel.url}`;
    }
    return `/${this.getPanelType(panel)}/${panel.value}`;
  }

  /*
   * Deactivated users should never be able to log-in to the system
   * only when impersonating a deactivated user we should get TRUE here
   */
  public get userActivated(): boolean {
    return this.config.userInfo?.userActivated;
  }

  public get hasApiDocs(): boolean {
    return this.config?.userInfo?.apiDocsUrl !== null;
  }

  public get apiDocsUrl(): undefined | string {
    return this.config?.userInfo?.apiDocsUrl;
  }

  public get rumDisabled(): boolean {
    return this.config?.userInfo?.config?.disableRUM;
  }

  get logoutUrl(): null | string {
    if (this.config && this.config.userInfo) {
      return this.config.userInfo.logoutUrl;
    }
    return null;
  }

  get searchItems(): IdentityItem[] {
    return this.appInfoService.config.impersonate
      /*
       * Hiding user searchItems when impersonating
       * Note: here we shouldn't return "[]" directly
       * because angular detects it as a change each time the getter is called
       */
      ? EMPTY_ARRAY
      : this.appInfoService.getUserPreference<IdentityItem[]>(PreferencesKeys.searchItems);
  }

  public get webAppVersion(): string | undefined {
    return this.serviceWorkerStateService.currentVersion?.text;
  }

  private panelTitle(panel: MenuItem): string {
    let dashboard = null;
    /*
     * The title of the panel is in the config for dashboard and page (not at the same place)
     * and in the panel itself for subpanel
     */
    if (panel.type === 'dashboard' || !panel.type) {
      dashboard = this.config.appConfig.dashboards.find(dashboard => dashboard.id === panel.value);
    } else if (panel.type === 'page') {
      dashboard = this.config.appConfig.pages.find(page => page.id === panel.value);
    } else if (panel.type === 'link') {
      dashboard = (this.config.appConfig?.links || []).find(link => link.id === panel.value);
    }

    const title = dashboard ? dashboard.title : panel.title;
    if (!title) {
      console.warn('Could not find panel title for: ' + panel.value);
    }
    return title;
  }

  public getPanelStyle(panel: MenuItem): { 'background-color': Color } {
    if (
      !this.errorService.isActive
      && panel.type === 'submenu'
      && panel.subpanels.find(sp => sp.value === this.selectedPanel)
    ) {
      return { 'background-color': this.uiService.activePanelColor() };
    }

    if (this.selectedPanel === panel.value) {
      return { 'background-color': this.uiService.activePanelColor() };
    }

    const backgroundColor = panel.background ?? this.uiService.navbarColor();
    return { 'background-color': backgroundColor };
  }

  public canShowPanel(panel: MenuItem): boolean {
    // admin panels are in the right menu
    if (panel.type === 'submenu') {
      return panel.subpanels.some(subpanel => this.canShowPanel(subpanel));
    }
    if (panel.type === 'page') {
      const pageValue = 'page/' + panel.value;
      return this.validDashboard(pageValue);
    }
    return this.validDashboard(panel.value);
  }

  protected validDashboard(name: string): boolean {
    if (this.config.isFullAdmin) {
      return true;
    }

    return (
      this.config.isFullAdmin || (name in this.config.dashboards)
    );
  }

  public collapseMenuIfNecessary(): void {
    const rightMenuWidth = document.querySelector('.right-menu').clientWidth;
    const logoWidth = document.querySelector('.navbar-brand').clientWidth;
    const appSwitcherWidth = document.querySelector('app-switcher')?.clientWidth ?? 0;
    const headerWidth = document.querySelector('.navbar-header').clientWidth;

    const menuWidth = headerWidth - rightMenuWidth - appSwitcherWidth - logoWidth;

    // estimate panel length based on number of char in title
    const panelsLength = sum(
      this.panels.map(d => this.panelTitle(d) ? textWidth(this.panelTitle(d), 14) : 10),
    );
    const panelBadgesCharacters = sum(
      this.panels.map(d => (d.mark ? d.mark.text.length : 0)),
    );

    // 30px for space between panels and 5px per each badge character
    const estimatedMenuWidth = panelsLength + this.panels.length * 30
      + panelBadgesCharacters * 5;

    this.collapseMenu = menuWidth < estimatedMenuWidth ? true : false;

    this.cdRef.detectChanges();

    this.collapseTextMenu = menuWidth < 120 ? true : false;
  }

  public optionSelected(option: IdentityItem): void {
    /*
     * if the options is null it means the user clicked or selected
     * something not-bound (probably the show All option) in the search bar
     */
    if (!option) return;

    const url = `/dashboard/page/${option.type}`;
    const queryParams = {};
    queryParams[option.idField] = option.id;

    this.ngZone.run(() => this.router.navigate([url], { queryParams }));
    // Add the selected option to the user's search history
    this.saveUserSearchHistory(option);
  }

  private saveUserSearchHistory(option: IdentityItem): void {
    const searchItems = this.appInfoService.getUserPreference<IdentityItem[]>(PreferencesKeys.searchItems)
      ?? [];

    const itemToSave = { id: option.id, title: option.title, type: option.type };
    const existingSearchItem = searchItems.find(
      i => i.id === itemToSave.id && i.type === itemToSave.type,
    );

    // If the current item does not exist in the search history
    if (!existingSearchItem) {
      // If the history already has 10 items, we remove the first one before adding the new item.
      if (searchItems.length === 10) {
        searchItems.shift();
      }
      // We add the item at the end of the array.
      searchItems.push(itemToSave);
    } else if (searchItems.length !== 1) {
      /*
       * If the item already exists in the search history, and the search history has more than 1 item,
       * (If it only has 1 item it means it's the same as the current item so we don't do anything)
       * we remove it and add it back at the end of the search history.
       */
      searchItems.push(
        searchItems.splice(searchItems.indexOf(existingSearchItem), 1)[0],
      );
    }

    this.appInfoService.saveUserPreference(PreferencesKeys.searchItems, searchItems);
  }

  public reloadPage(event: any): void {
    /*
     * If the user presses shift key or ctrl, the dashboard has to be open in a new tab/window
     * so in this case we won't do anything
     */
    if (DatabaseHelper.isSpecialClick(event)) {
      return;
    }
    event.preventDefault();
    const href = event.target.href;
    // for an unknown reason (arriving from an unexpected element click probably), href is undefined
    if (!href) return;
    this.selectedPanel = href.match(/dashboard\/([-\w]+)/)[1];
    if (this.selectedPanel === 'page') {
      this.selectedPanel = href.match(NavigationHelper.PAGE_REGEXP)[1];
    }
    if (document.location.href.match(href)) {
      document.location.href = href;
    }

    if (this.$searchBar) {
      this.$searchBar.updateSearchResults();
    }
  }

  public impersonateTarget: string;

  public impersonate(userItem: IdentityItem): void {
    if (userItem) {
      this.impersonateTarget = userItem.title;
      let currentHost = `${window.location.protocol}//${window.location.hostname}`;
      if (window.location.port) {
        currentHost = currentHost + ':' + window.location.port;
      }

      /*
       * We redirect to the current user's default dashboard
       * Note that this dashboard might not be available to the impersonated user
       */
      const targetUrl = `${currentHost}/?${Config.SPINPERSONATE}=${encodeURIComponent(this.impersonateTarget)}`;
      const win = window.open(targetUrl, '_blank');
      win.focus();
    }
  }

  get impersonatingUser(): string {
    return this.config.impersonate;
  }

  get spinergieUserName(): string {
    return this.config.spinergieUser;
  }

  endImpersonation(): void {
    this.config.impersonate = null;
    window.sessionStorage.removeItem(Config.SPINPERSONATE);
    location.reload();
  }

  showHideImpersonation(value: boolean, $event): void {
    this.showImpersonation = value;
    $event.stopPropagation();
  }

  get impersonationStyle(): null | { height: string; width: string } {
    if (this.showImpersonation) {
      return null;
    } else {
      return {
        height: '2px',
        width: '20px',
      };
    }
  }

  /**
   * Dev Mode Toolbox
   */
  public isInDevMode(): boolean {
    return this.config.userInfo.isDevMode;
  }

  public canExpandDevToolbox(): boolean {
    return this.config.devSettings?.showToolbox;
  }

  public getDevSetting(setting: string): boolean {
    return this.config.getDevSetting(setting);
  }

  public toggleDevSetting(setting: string): void {
    this.config.toggleDevSetting(setting);
  }

  public getVesselAutoReloadState(): boolean {
    return this.config.autoReloadEnabled;
  }

  public getInternetAutoReloadState(): boolean {
    return this.config.getInternetAutoReloadState();
  }

  public reloadPageConfig(): void {
    this.appInfoService.reloadComponentsPage();
  }

  public expandDevToolbox(value: boolean): void {
    this.config.devSettings.showToolbox = value;
    this.config.saveDevSettingsToSessionStorage();
  }

  public updateVesselAutoreloadState(): void {
    this.config.devSettings.vesselAutoreload = !this.config.devSettings.vesselAutoreload;
    this.config.saveDevSettingsToSessionStorage();
  }

  public updateInternetAutoreloadState(): void {
    this.config.devSettings.internetAutoreload = !this.config.devSettings.internetAutoreload;
    this.config.saveDevSettingsToSessionStorage();
    this.updateInternetStatusServiceChecking();
  }

  public async onBookmarkSelect(bookmark: Bookmark): Promise<void> {
    /*
     * we will clear completely persistent filters
     * if we would keep any persistent filters, the state of them might not be inline
     * with the state of the bookmark. If the user continues the navigation after the bookmark
     * only those persistent filters which are part of the bookmark will be kept
     */
    this.config.persistentFilterState = {};
    const queryParams = { ...bookmark.queryParams };
    const options: NavigationExtraState = { resetState: true };
    if (bookmark.osvProjectIds) {
      queryParams[Config.SELECTED_PROJECTS] = bookmark.osvProjectIds.toString();
    }
    if (bookmark.vesselFleets?.length) {
      await this.applyFleets(bookmark.vesselFleets);
      options.reloadData = true;
    }
    this.routerService.navigateWithExtraState(bookmark.url, queryParams, options);
  }

  public async onVesselFleetSelected(vesselFleets: VesselFleet[]): Promise<void> {
    await this.applyFleets(vesselFleets.map(f => f.id));
    this.routerService.navigateOnSameUrl({ reloadData: true });
  }

  private async applyFleets(fleetIds: number[]): Promise<void> {
    this.dialogManager.panelLoading('loadVesselFleet', 'Applying fleet...', true);
    this.config.storeCurrentFleets(fleetIds);
    NavigationHelper.serializeToCurrentUrl({ [Config.SELECTEDFLEETS]: fleetIds.toString() });
    await this.appInfoService.saveUserPreference(PreferencesKeys.selectedFleets, this.config.selectedFleets);
    this.routeReuseStrategy.clearDetachedRoutes(); // So that previously used components are recreated.
    await this.dataLoader.clearCacheAndReloadUserVesselFleetSpecs();
    this.dialogManager.panelLoaded('loadVesselFleet');
  }

  /*
   * Close the side panel when clicking outside of it.
   * Normally we would use focusout event, but there are many exceptions (modal within sidebar, etc),
   * so this way is actually better
   */
  focusOutListener = (e: MouseEvent): void => {
    const clientWidth = document.body.getBoundingClientRect().width;
    const sidebarWidth = this.$sideDrawer._content.nativeElement.getBoundingClientRect().width;
    const headerHeight = document.getElementById('header').getBoundingClientRect().height;
    if (e.clientX < (clientWidth - sidebarWidth) && e.clientY > headerHeight) {
      this.closeAndApply();
    }
  };

  public closeAndApply(): void {
    this.$sideDrawer.close();
    this.openedSideMenu = null;
    if (this.hasOsvProjectChanged) {
      this.applyProjectScopeSelect();
      this.hasOsvProjectChanged = false;
    }
    document.body.removeEventListener('click', this.focusOutListener);
  }

  /*
   * Toggle the right panel and setup listener to close it when focused out
   * We use setTimeout for listener setup because otherwise the listener executes immediately
   */
  public drawerToggle(): void {
    if (!this.$sideDrawer.opened) {
      setTimeout(() => document.body.addEventListener('click', this.focusOutListener), 0);
    } else {
      setTimeout(() => document.body.removeEventListener('click', this.focusOutListener), 0);
    }
    this.$sideDrawer.toggle();
  }

  public sideMenuToggle(selectedMenu: SideMenu): void {
    const needsToggling = this.openedSideMenu === SideMenu.Closed || this.openedSideMenu === selectedMenu;
    this.openedSideMenu = this.openedSideMenu === selectedMenu ? null : selectedMenu;
    this.fleetSidebarLoaded ??= this.openedSideMenu === SideMenu.Fleet; // Used to lazy-load fleet menu

    if (!needsToggling) return;
    this.drawerToggle();
    this.cdRef.detectChanges();
  }

  public closeSideMenu(): void {
    if (this.openedSideMenu === SideMenu.Closed) return;
    this.sideMenuToggle(this.openedSideMenu);
  }

  public hasOsvProjects(): boolean {
    return this.config?.userInfo?.accessibleOsvProjects?.length > 1;
  }

  public onProjectScopeSelect(hasOsvProjectChanged: boolean): void {
    this.hasOsvProjectChanged = hasOsvProjectChanged;
  }

  /**
   * Called when a project is clicked from the sidebar. It will:
   * - set the new selected projects in user preferences
   * - reload the page
   */
  public async applyProjectScopeSelect(): Promise<void> {
    await this.appInfoService.saveUserPreference(
      PreferencesKeys.selectedOsvProjectIds,
      this.config.selectedOsvProjectIds,
    );
    // we want to re-instantiate the components when choosing a project
    this.routeReuseStrategy.clearDetachedRoutes();
    this.routeReuseStrategy.recreateCurrentRoute();
    this.dataLoader.cancelPendingRequestsAndClearCache();
    /** Empty and reload ref data, as it can be filtered by project */
    await RefDataProvider.resetRefData();
    const targetUrl = this.router.url;
    window.sessionStorage[Config.SELECTED_PROJECTS] = this.config.selectedOsvProjectIds.toString();
    const url = new URL(window.location.href);
    url.searchParams.set(Config.SELECTED_PROJECTS, window.sessionStorage[Config.SELECTED_PROJECTS]);
    // Delete common filters, resetting sidebar state
    for (const param of Array.from(url.searchParams.keys())) {
      if (!param.includes('common-filters')) continue;
      url.searchParams.delete(param);
    }
    this.config.persistentFilterState = {};
    // Due to the way DPR works, do a hard refresh when using it. There may be a better way to do that
    if (targetUrl.includes('/dpr/')) {
      window.location.href = url.href;
      return;
    }
    // Refresh search bar items since they are filtered by project
    this.config.getSearchbarItems(this.dataLoader);
    // dashboard case: this will only reload components
    this.router.navigate([], {
      queryParams: Object.fromEntries(url.searchParams),
    });
  }

  public saveAnalysis(): void {
    this.displaySaveAnalysis = false;
    this.createNewBookmark = true;
    this.cdRef.detectChanges();
    this.sideMenuToggle(SideMenu.Bookmark);
  }

  public setCurrentPanelTitle(): void {
    this.selectedPanelTitle = '';
    if (!this.selectedPanel) {
      return;
    }
    this.panels.forEach(p => {
      if (p.value === this.selectedPanel) {
        this.selectedPanelTitle = this.panelTitle(p);
      }
      if (p.type === 'submenu') {
        // Looking in subpanels
        p.subpanels.forEach(sp => {
          if (sp.value === this.selectedPanel) {
            this.selectedPanelTitle = this.panelTitle(sp);
          }
        });
      }
    });
    /*
     * If we didn't find a title for the selectedPanel
     * we set as title the panel value
     */
    if (!this.selectedPanelTitle) {
      this.selectedPanelTitle = this.selectedPanel;
    }
  }

  public bookmarkStateChange(newStatus: boolean): void {
    if (this.openedSideMenu !== SideMenu.Bookmark) {
      return;
    }
    this.openedSideMenu = newStatus === true ? SideMenu.Bookmark : null;
    this.cdRef.detectChanges();
    if (this.createNewBookmark && this.$bookmarks) {
      this.$bookmarks.addNewBookmark();
      this.createNewBookmark = false;
    }
  }

  public hideSaveAnalysisClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.userHideSaveAnalysis = true;
  }

  public async createNewVesselFleet(titleAndFilters: VesselFleetInfo): Promise<void> {
    const vesselFleet = {
      updateVesselFleet: {
        ...titleAndFilters,
        id: null,
        dateVesselFleet: dayjs().valueOf(),
      },
    };
    const fullUrl = '/base/saving/vessel-fleet-update';
    let saveResponse: { vesselFleetId: number };
    try {
      saveResponse = await this.dataLoader.post<{ updateVesselFleet: VesselFleet }, typeof saveResponse>(
        fullUrl,
        vesselFleet,
      );
    } catch (err) {
      this.dialogManager.showMessage(err, 'error');
      return;
    }
    this.dialogManager.showMessage('Your fleet has been saved', 'success');
    this.config.lastFleetCreatedId = saveResponse.vesselFleetId;
    await this.config.loadAvailableVesselFleets();
    /*
     * if vessel fleet sidebar is currently open we force
     * the reload of fleets
     */
    if (this.$vesselFleetSidebar) {
      this.$vesselFleetSidebar.prepareVesselFleets();
    }
    this.cdRef.detectChanges();
  }

  public enterLastCreateFleet(): void {
    this.onVesselFleetSelected([{ id: this.config.lastFleetCreatedId } as VesselFleet]);
    /*
     * if vessel fleet sidebar is currently open we force
     * the sidebar to update its selected fleet list
     */
    if (this.$vesselFleetSidebar) {
      this.$vesselFleetSidebar.updateSelectedFleets();
    }
  }

  /**
   * Set user timezone
   *
   * @param  {string} code    Timezone code (ISO), 'utc' (default) or 'local'
   * @param  {Event}  $event  Click event
   */
  public setTimezone(code: string, $event?: Event): void {
    if ($event) $event.preventDefault();
    this.timezoneService.setLocalTimezone(code);
  }

  /**
   * Append custom timezone
   *
   * @param  {IdentityItem} item    Timezone item
   */
  public appendTimezone(item: IdentityItem): void {
    if (!item) {
      return;
    }
    this.timezones.push({ code: item.id, title: `${item.title} (${item.subtitle})` });
    /** Update available timezones */
    this.availableTimezones = DateHelper.getIdentityItemOfficialTimezones(this.timezones.map(tz => {
      return { title: tz.code };
    }));
    // Auto-select
    this.timezoneService.setLocalTimezone(item.id);
  }

  public hasDatabaseOverviewAccess(): boolean {
    return this.config.hasFeature('analyst')
      || (this.config?.appConfig?.settings && 'database-overview' in this.config.appConfig.settings)
        && !this.uiService.isSmallDisplay();
  }

  public getNumberSelectedOsvProjects(): string | number {
    const nbAccessible = this.config?.userInfo?.accessibleOsvProjects?.length;
    const nbSelected = this.config.selectedOsvProjectIds?.length;
    return nbAccessible === nbSelected ? 'All' : nbSelected;
  }

  public canRequestNewVessel(): boolean {
    return this.config.product === 'construction';
  }

  public navigateHomePage(): void {
    this.router.navigate(['/']);
  }

  public internetStatusToggleChange(): void {
    this.productAnalyticsService.trackAction(
      'onlineStatusUpdated',
      { isOffline: !this.internetStatusService.isOnline() },
    );
    this.internetStatusService.toggle();
  }
}
