<div class="bookmarker">
  <div class="sidebar-header">
    <div class="title">Bookmarks</div>
    <div class="search-and-save">
      <pearl-form-field label="Search ..." iconPrefix="search">
        <input matInput [(ngModel)]="searchText" autocomplete="off" />
      </pearl-form-field>
      <button
        class="header-button"
        mat-flat-button
        (click)="addNewBookmark()"
        [disabled]="!canAddNewBookmark"
        [ngClass]="{ 'header-button-disabled': !canAddNewBookmark }">
        <mat-icon>add</mat-icon> Save current analysis
      </button>
    </div>
  </div>
  <div class="sidebar-list">
    <div id="top"></div>
    <a
      *ngFor="let bookmark of bookmarks | searchSidebarItem: searchText"
      class="item"
      (click)="navigateBookmark($event, bookmark)"
      [ngClass]="{ selected: checkIfBookmarkShouldAppearVisible(bookmark) }"
      [href]="bookmark.href">
      <div class="item-information">
        <div class="title-box">
          <div class="title" *ngIf="!bookmark.editing">{{ bookmark.title }}</div>
          <div class="title-editing" *ngIf="bookmark.editing">
            <pearl-form-field
              label="Enter your title"
              [showError]="bookmark.newTitle.invalid"
              [errorMessage]="getErrorMessage(bookmark.newTitle) ?? ''">
              <input
                matInput
                #titleInput
                (keydown.enter)="onTitleEnter($event, bookmark)"
                [formControl]="bookmark.newTitle"
                required
                cdkFocusInitial
                autocomplete="off" />
              <mat-error *ngIf="bookmark.newTitle.invalid"> {{ getErrorMessage(bookmark.newTitle) }} </mat-error>
            </pearl-form-field>
          </div>
        </div>
        <div class="subtitle">{{ bookmark.dashboard | upperFirstLetter }}</div>
        <div class="date">{{ getFormattedDate(bookmark.dateBookmark) }}</div>
      </div>

      <button
        mat-icon-button
        class="in-bookmark-icon"
        *ngIf="!bookmark.editing && !bookmark.deleting && bookmark.vesselFleets?.length">
        <mat-icon
          matBadge="{{ bookmark.vesselFleets.length }}"
          matBadgeSize="small"
          [matBadgeHidden]="!bookmark.vesselFleets.length"
          matBadgeColor="accent">
          directions_boat
        </mat-icon>
      </button>
      <button
        mat-icon-button
        class="in-bookmark-icon"
        *ngIf="
          !bookmark.editing &&
          !bookmark.deleting &&
          bookmark.accessibleOsvProjects?.length &&
          config.userInfo?.accessibleOsvProjects?.length > 1
        "
        spinTooltip="{{ projectsToString(bookmark.accessibleOsvProjects) }}">
        <mat-icon
          matBadge="{{ bookmark.accessibleOsvProjects.length }}"
          matBadgeSize="small"
          [matBadgeHidden]="!bookmark.accessibleOsvProjects.length"
          matBadgeColor="accent">
          dashboard
        </mat-icon>
      </button>
      <div class="action-buttons">
        <button
          *ngIf="!bookmark.editing && !bookmark.deleting"
          mat-button
          class="more-button"
          [matMenuTriggerFor]="addMenu"
          matTooltip="Actions"
          matTooltipPosition="above"
          (click)="preventDefault($event)">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #addMenu="matMenu" class="edit-menu">
          <button
            class="edit-button"
            (click)="enterEditMode($event, bookmark)"
            mat-icon-button
            color="primary"
            *ngIf="!bookmark.editing"
            [matTooltip]="'Edit'">
            <mat-icon>create</mat-icon>
          </button>
          <button
            class="delete-button"
            (click)="enterDeletingMode($event, bookmark)"
            mat-icon-button
            color="warn"
            *ngIf="!bookmark.editing"
            matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-menu>
        <button
          class="save-button"
          (click)="saveBookmark($event, bookmark)"
          mat-icon-button
          color="primary"
          *ngIf="bookmark.editing"
          [disabled]="!bookmark.newTitle.value"
          matTooltip="Save">
          <mat-icon>save</mat-icon>
        </button>
        <button
          class="delete-button"
          (click)="deleteBookmark($event, bookmark)"
          mat-icon-button
          color="warn"
          *ngIf="bookmark.deleting"
          matTooltip="Delete">
          <mat-icon>delete</mat-icon>
        </button>
        <button
          class="cancel-button"
          (click)="cancelAction($event, bookmark)"
          mat-icon-button
          color="primary"
          *ngIf="bookmark.editing || bookmark.deleting"
          matTooltip="Cancel">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </a>
  </div>
</div>
