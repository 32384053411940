import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, Output, ViewChild,
  ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { cloneDeep, remove } from 'lodash-es';
import dayjs from 'dayjs';

import { DataLoader } from '../data-loader/data-loader';
import { DialogManager } from '../database/dialog-manager';
import { LayerFilter, VesselData } from '../helpers/types';
import { Config } from '../config/config';
import { AppInfoService } from '../app/app-info-service';
import { SelectorState } from '../selector/selector.types';
import { DateHelper } from '../helpers/date-helper';
import { DatabaseHelper } from '../database/database-helper';
import { VesselFleet } from './user-saving-types';
import { VesselFleetSidebarComponent } from './vessel-fleet-sidebar';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { SearchSidebarItemPipe, UpperFirstLetterPipe } from '../helpers/pipes';
import { FailedResponse } from '../data-loader/data-loader.types';
import { PearlFormFieldComponent, PearlIconComponent } from '../shared/pearl-components';

export interface FleetSidebarDialogData {
  deleted?: boolean;
  bookmarks?: string[];
  linkedAlerts?: { userSubscriptionId: number; subscriptionTitle: string }[];
  modified?: boolean;
}

@Component({
  selector: 'vessel-fleet-list',
  templateUrl: './vessel-fleet-list.html',
  styleUrls: ['vessel-fleet-sidebar.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    PearlFormFieldComponent,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatMenuModule,
    UpperFirstLetterPipe,
    SearchSidebarItemPipe,
    PearlIconComponent,
  ],
})
export class VesselFleetListComponent {
  constructor(injector: Injector, public fleetSidebarDialog: MatDialog) {
    this.dataLoader = injector.get(DataLoader);
    this.cdRef = injector.get(ChangeDetectorRef);
    this.dialogManager = injector.get(DialogManager);
    this.config = injector.get(Config);
    this.appInfoService = injector.get(AppInfoService);
    this.analyticsService = injector.get(ProductAnalyticsService);
  }
  protected dataLoader: DataLoader;
  public cdRef: ChangeDetectorRef;
  private dialogManager: DialogManager;
  public config: Config;
  private appInfoService: AppInfoService;
  private analyticsService: ProductAnalyticsService;

  @Output()
  shareFleet = new EventEmitter<VesselFleet>();
  @Output()
  onselect = new EventEmitter<any>();

  @Input()
  vesselFleets: VesselFleet[] = [];
  @Input()
  listTitle: string = '';
  @Input()
  displayTitle: boolean = true;
  @Input()
  searchText: string = '';
  @Input()
  vesselDataset: VesselData[] = [];
  @Input()
  canEdit: boolean;

  @ViewChild('titleInput')
  titleInput: Element;

  public loadExistingVesselFleet(vesselFleet: VesselFleet): void {
    const appliedFilters = cloneDeep(vesselFleet.filters);

    const selectorState: SelectorState = {
      ...this.config.appConfig.fleetBuilder,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      afterFilterAction: filters => this.afterEditVesselFleetFilters(filters, vesselFleet),
      appliedFilters,
      fleetBuilderMode: 'edit',
    };
    this.dialogManager.openSelectorDialog(selectorState);
  }

  public vesselFleetCountInfo(vesselFleet: VesselFleet): string {
    if (vesselFleet.vesselsCount === vesselFleet.activeVesselsCount) {
      return `${vesselFleet.vesselsCount} vessels`;
    }
    return `${vesselFleet.vesselsCount} vessels (${vesselFleet.activeVesselsCount} active)`;
  }

  public async afterEditVesselFleetFilters(selectorFilters: LayerFilter, vesselFleet: VesselFleet): Promise<void> {
    vesselFleet.filters = selectorFilters;
    VesselFleetSidebarComponent.updateVesselCounts(vesselFleet, this.vesselDataset);
    await this.saveVesselFleet(vesselFleet, vesselFleet.selected);
    /*
     * If the edited vesselFleet was already selected we select this vesselFleet
     * Otherwise we open a window where the user is asked if he want to enter in this vesselFleet
     */
    if (vesselFleet.selected) {
      this.selectVesselFleet(null, vesselFleet, true);
    } else {
      this.askIfApplyVesselFleet(vesselFleet);
    }
  }

  public askIfApplyVesselFleet(vesselFleet: VesselFleet): void {
    const dialogRef = this.fleetSidebarDialog.open(FleetSidebarDialog, {
      data: { modified: true } as FleetSidebarDialogData,
      panelClass: 'spin-dialog-box',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'skip') {
        return;
      }
      this.selectVesselFleet(null, vesselFleet, true);
    });
  }

  public selectVesselFleet(event: MouseEvent, vesselFleet: VesselFleet, forceValue: boolean = null): void {
    if (!vesselFleet || vesselFleet.editing) {
      return;
    }
    /*
     * If user click on a vessel scope without a special click (ctr+ click / cmd + click)
     * we unselected all other vessels scope and just select the last one
     */
    if (event && !DatabaseHelper.isSpecialClick(event)) {
      const newValue = !this.vesselFleets.find(f => f.id === vesselFleet.id).selected;
      /*
       * we deselect the other vesselFleets selected only if the vessel fleet wasn't selected before
       * otherwise we just deselect this specific vessel fleet
       */
      if (newValue) {
        this.vesselFleets.forEach(f => {
          if (f.id !== vesselFleet.id) {
            f.selected = false;
          }
        });
      }
    }
    this.selectVesselFleetById(vesselFleet.id, forceValue);
    this.onselect.emit();
  }

  public selectVesselFleetById(id: number, forceValue: boolean = null): void {
    this.vesselFleets.forEach(vs => {
      if (vs.id === id) {
        vs.selected = forceValue !== null ? forceValue : !vs.selected;
        const trackedData = { id: vs.id, title: vs.title, sharedFleet: vs.sharedFleet };
        this.analyticsService.trackAction(vs.selected ? 'vesselFleetApplied' : 'vesselFleetUnapplied', trackedData);
      }
    });
  }

  public preventDefault(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public cancelAction(event: MouseEvent, vesselFleet: VesselFleet): void {
    this.preventDefault(event);
    /*
     * If we cancel and the vessel fleet has not id it means we were creating this vessel fleet
     * so we have to delete it
     */
    vesselFleet.deleting = false;
    vesselFleet.editing = false;
    vesselFleet.newTitle = new FormControl('', [Validators.required]);
    vesselFleet.newSharedFleet = vesselFleet.sharedFleet;
  }

  public enterDeletingMode(event: MouseEvent, vesselFleet: VesselFleet): void {
    this.preventDefault(event);
    vesselFleet.deleting = true;
  }

  protected async deleteVesselFleet(event: MouseEvent, vesselFleet: VesselFleet): Promise<void> {
    // Avoid trigger navigation event
    this.preventDefault(event);

    if (vesselFleet.id) {
      const deleteVesselFleet = {
        deleteId: vesselFleet.id,
      };
      const fullUrl = '/base/saving/vessel-fleet-delete';
      return this.dataLoader.post<{ deleteId: number }, FailedResponse>(
        fullUrl,
        deleteVesselFleet,
      )
        .then(() => {
          this.dialogManager.showMessage('Your fleet has been deleted', 'success');
        })
        .catch(error => {
          this.dialogManager.showMessage(error, 'error');
        });
    }

    if (vesselFleet.selected) {
      this.selectVesselFleet(event, vesselFleet);
    }
    /*
     * Notify appInfoService that user deleted a vessel fleet
     * it's useful to notify bookmarker to update its bookmark list
     */
    this.appInfoService.userDeletedVesselFleet();
    remove(this.vesselFleets, (vf: VesselFleet) => vf.id === vesselFleet.id);
    this.cdRef.detectChanges();
  }

  public enterEditMode(event: MouseEvent, vesselFleet: VesselFleet): void {
    event.preventDefault();
    vesselFleet.editing = true;
    vesselFleet.newTitle = new FormControl(vesselFleet.title, [Validators.required]);
    vesselFleet.newTitle.markAsTouched();
    vesselFleet.newSharedFleet = vesselFleet.sharedFleet;
  }

  public onTitleEnter(event: Event, vesselFleet: VesselFleet): void {
    if (!vesselFleet.newTitle || !vesselFleet.newTitle.value) {
      return;
    }
    this.saveClick(event, vesselFleet);
  }

  public saveClick(event: Event, vesselFleet: VesselFleet): void {
    this.preventDefault(event);
    this.saveVesselFleet(vesselFleet, vesselFleet.selected);
  }

  public saveVesselFleet(vesselFleet: VesselFleet, forceValue: boolean = null): Promise<void> {
    const editVesselFleet = {
      updateVesselFleet: {
        id: vesselFleet.id,
        title: vesselFleet.newTitle.value || vesselFleet.title,
        dateVesselFleet: dayjs().valueOf(),
        filters: vesselFleet.filters,
        sharedFleet: vesselFleet.newSharedFleet,
      },
    };
    const fullUrl = '/base/saving/vessel-fleet-update';
    return this.dataLoader
      .post<{ updateVesselFleet: VesselFleet }, FailedResponse & { vesselFleetId: number }>(
        fullUrl,
        editVesselFleet,
      )
      .then(() => {
        this.dialogManager.showMessage('Your fleet has been saved', 'success');
        vesselFleet.editing = false;
        vesselFleet.title = vesselFleet.newTitle.value ? vesselFleet.newTitle.value : vesselFleet.title;
        if (vesselFleet.sharedFleet !== vesselFleet.newSharedFleet) {
          vesselFleet.sharedFleet = vesselFleet.newSharedFleet;
          this.shareFleet.emit(vesselFleet);
        }
        this.selectVesselFleetById(vesselFleet.id, forceValue);
        this.cdRef.detectChanges();
      })
      .catch(error => {
        this.dialogManager.showMessage(error, 'error');
      });
  }

  public checkIfCanDeleteVesselFleet(event: MouseEvent, vesselFleet: VesselFleet): void {
    // Avoid trigger navigation event
    this.preventDefault(event);
    if ((vesselFleet.bookmarks?.length) || (vesselFleet.linkedAlerts.length)) {
      const dialogRef = this.fleetSidebarDialog.open(FleetSidebarDialog, {
        data: {
          bookmarks: vesselFleet.bookmarkTitles,
          linkedAlerts: vesselFleet.linkedAlerts,
          deleted: true,
        } as FleetSidebarDialogData,
        panelClass: 'spin-dialog-box',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'skip') {
          return;
        }
        this.deleteVesselFleet(event, vesselFleet);
      });
    } else {
      this.deleteVesselFleet(event, vesselFleet);
    }
  }

  public getErrorMessage(title: FormControl): string {
    if (title.hasError('required')) {
      return 'You must enter a title';
    }

    return '';
  }

  public getFormattedDate(timestamp: number): string {
    return DateHelper.formatDatetime(timestamp, 'YYYY-MM-DD HH:mm');
  }
}

@Component({
  selector: 'fleet-sidebar-dialog',
  templateUrl: 'fleet-sidebar-dialog.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatIconModule,
    NgFor,
    MatButtonModule,
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FleetSidebarDialog {
  constructor(
    public dialogRef: MatDialogRef<FleetSidebarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: FleetSidebarDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close('skip');
  }
}
